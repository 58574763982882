import {Component, OnInit, ViewChild, AfterViewInit} from '@angular/core';
import {CoinSearchData} from './datamodels';
import {CoinData} from './datamodels';
import {CoinsearchService} from '../coinsearch.service';
import {ImportAllMaterialModule} from '../material-module';
import {MatTableDataSource} from '@angular/material/table';
import {MatPaginator} from '@angular/material/paginator';
import {ActivatedRoute} from '@angular/router';

@Component({
    selector: 'coinsearch-form',
    templateUrl: './coinsearch-form.component.html',
    styleUrls: ['./coinsearch-form.component.css'],
    standalone: false
})
export class CoinsearchFormComponent implements OnInit, AfterViewInit {
  searchQuery = new CoinSearchData('', '','','',false);
  public coinList: CoinData[] = [];
  public displayedColumns: string[] = ['index', 'obverse', 'reverse', 'title', 'category', 'diameter'];
  public dataSource = new MatTableDataSource<CoinData>(this.coinList);
  public isWait = false;
  public coinNotes = '';

  @ViewChild('paginator', {static: true}) paginator!: MatPaginator;

  //@ViewChild(MatPaginator, {static: true}) paginator: MatPaginator=new MatPaginator(null,null,null);


  ngOnInit() {
    this.dataSource.paginator = this.paginator;
    const qcat = this._Activatedroute.snapshot.paramMap.get('qcat');

    if (this._coinSearchService.getLastQueryResult() === null ||
      (qcat && this._coinSearchService.getLastQueryParams().categoryID !== qcat)) {
      if (qcat !== '' && qcat != null) {
        console.log('link routing');
        console.log('qcat:' + qcat);
        this.searchQuery.queryNID = '';
        this.searchQuery.categoryID = qcat;
        this.searchQuery.showCollectors = false;
        this._coinSearchService.setLastSearchPage(0);
        this.getData();
      }
    } else {
      this.searchQuery = this._coinSearchService.getLastQueryParams();
      this.renderSearch(this._coinSearchService.getLastQueryResult());
    }
  }

  ngAfterViewInit(): void {

  }

  constructor(private _coinSearchService: CoinsearchService, private _Activatedroute: ActivatedRoute) {

  }

  renderSearch(coinList: any) {
    console.log('OK',coinList);
    this.isWait = false;
    for (const coin of coinList) {
      //console.log(coin);
      const ir = coin.imageReverseSmall;
      const io = coin.imageObverseSmall;
      let priceInfo='';
      let priceCategory='CAT0'; 
      //console.log(coin['gradeData'])
      for (const gr in coin['gradeData']){
        if (coin['gradeData'][gr][1]!='' && coin['gradeData'][gr][1]!='-'){
          priceInfo=priceInfo+'['+coin['gradeData'][gr][0]+"-"+coin['gradeData'][gr][1]+"]";
        }
        //console.log(coin['gradeData'][gr]);

      }

      const c: CoinData = new CoinData(coin.title, 
          coin.uidNGC,
          coin.diameter,
          coin.categoryCode,
          coin.link,
          io,
          ir,
          coin.rcid,
          coin['gradeData'],
          priceCategory,
          coin.sms,
          coin.proof
          );
      
      this.coinList.push(c);
    }
    this.dataSource = new MatTableDataSource<CoinData>(this.coinList);
    this.dataSource.paginator = this.paginator;

    // a hacky solution from https://stackoverflow.com/questions/39787038/how-to-manage-angular2-expression-has-changed-after-it-was-checked-exception-w
    setTimeout(() => this.paginator.pageIndex = this._coinSearchService.getLastSearchPage());

    this._coinSearchService.setLastQueryResult(coinList);
  }

  onPaginateChange(event: any) {
    this._coinSearchService.setLastSearchPage(event.pageIndex);
  }

  trackByIndex(index: number, obj: any): any {
    return index;
  }

  emptyStr(value: string) {
    return (!value || value === undefined || value === '' || value.length === 0);
  }

  getData() {
    if (this.emptyStr(this.searchQuery.categoryID) && this.emptyStr(this.searchQuery.queryNID)) {
      return;
    }
    this.coinList = [];
    this.isWait = true;
    this._coinSearchService.enroll(this.searchQuery)
      .subscribe(
        data => this.renderSearch(data),
        error => console.error('Error!', error)
      );
  }

  addNote(note: string) {
    this.coinNotes += note + '\n';
  }

  onSubmit() {
    console.log(this.searchQuery);
    this._coinSearchService.setLastSearchPage(0);
    this.getData();
  }


}
