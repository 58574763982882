import { Component, OnInit } from '@angular/core';
import { CoinsearchService,RCUserProfile } from '../coinsearch.service';
import { KeycloakService } from 'keycloak-angular';
import { Router } from '@angular/router';


@Component({
  selector: 'maintoolbar',
  templateUrl: './maintoolbar.component.html',
  styleUrls: ['./maintoolbar.component.css']
})
export class MaintoolbarComponent implements OnInit {

  public rcUserProfile      : RCUserProfile=new RCUserProfile();
  public isloggedIn : boolean = false;
  public isadmin : boolean = false;
  public keycloakAuthURI :string = '';
  public avatarInitials :any = null;
  constructor(private readonly keycloak: KeycloakService,private _coinSearchService: CoinsearchService, private router: Router) { 
    this._coinSearchService.getRCUserProfile().then(response => {
      if (response!=null){
        this.rcUserProfile=response;
      }
    })
  }
  public showMessageWindow = false;
  public message = '';

  showMessage(msg:string) {
    this.showMessageWindow = true;
    this.message = msg;

    setTimeout(() => {
      this.showMessageWindow = false;
      this.message = '';
    }, 2000);
  }
  public initSession() {
    this.keycloak.login();
  }

  public closeSession() {
    this.keycloak.logout();
  }

  public copyExternalFiles() {
    this.showMessage('External files copy started...');
    console.log("copyExternalFiles");
    this._coinSearchService.copyExternalFiles().subscribe(
      result => {
        this.showMessage('External files copied!');
        
        },
      error => console.error('Error!', error)
    );
  }

  openKeycloakAuthURI(): void {
    window.open(this.keycloakAuthURI, '_blank');
  }

  ngOnInit(): void {
    this.keycloak.isLoggedIn().then((loggedIn) => {
      if (!loggedIn) {
        this.router.navigate(['/']);
      }
      var kuri=this._coinSearchService.getKeycloakURI();
      if (!kuri.endsWith('/')) {
        kuri += '/';
      }
      this.keycloakAuthURI = kuri+'realms/robocoin/device';
      console.log('keycloakAuthURI:'+this.keycloakAuthURI);
    });

  }
  showMonitor() {
		var iframe = '<html><head><title>Robocoin scan monitor</title><style>body, html {width: 100%; height: 100%; margin: 0; padding: 0}</style></head><body><iframe src="https://app.robocoin.ai/lastscan" style="height:calc(100% - 4px);width:calc(100% - 4px)"></iframe></body></html>';

		var win = window.open("","","width=410,height=480,toolbar=no,menubar=no,resizable=no");
		win.document.write(iframe);
	}
  

}
