import { Component, OnInit } from '@angular/core';
import { ElementRef, ViewChild, AfterViewInit, OnDestroy } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { CoinsearchService,RCUserProfile } from '../coinsearch.service';
import { DomSanitizer, SafeUrl} from '@angular/platform-browser';
import { Router } from '@angular/router';
import {CoinSearchData} from '../coinsearch-form/datamodels';
import {formatDate} from '@angular/common';
import { Subscription, interval } from 'rxjs';
import { startWith } from 'rxjs/operators';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from "@angular/material/form-field";

@Component({
    selector: 'app-coinscanview',
    templateUrl: './coinscanview.component.html',
    styleUrls: ['./coinscanview.component.css'],
    standalone: false
})
export class CoinscanviewComponent implements OnInit,AfterViewInit, OnDestroy  {
  @ViewChild('imgObverse') imgObverse: ElementRef= new ElementRef(null);
  @ViewChild('imgReverse') imgReverse: ElementRef= new ElementRef(null);
  @ViewChild('lens1') lens1: ElementRef= new ElementRef(null);
  @ViewChild('lens2') lens2: ElementRef= new ElementRef(null);

  subscription: Subscription= new Subscription();
  public rcUserProfile      : RCUserProfile=new RCUserProfile();
  public coinData: any  = null;
  public coinFeaturesDef: any  = null;
  public coinBetaFeatures: any  = null;
  public customId:string = ""; 
  public rotationAmount1 = 0;
  public rotationAmount2 = 0;
  public categories:any  = null;
  public category:string = "";
  public coinQuality:string="";
  public coindate:number = 0;
  public coindiamater:number = 0;
  public invertScans:boolean = false;
  public isWait:boolean = false;
  public isTestSet = false;
  public mintsymbol = '';
  public showGrid = false;
  public imageSize = 500;
  public matchList = null;
  public coinTimeStamp='';
  public featureList: Map<string, string>=new Map<string, string>();
  public imageObverse:any = null;
  public imageReverse:any = null;
  public rulesValueIndicator:any = null;
  public nextCoinCatList: string[] = [];
  public nextCoinSelected: any = null;
  constructor(private _coinSearchService: CoinsearchService, private _Activatedroute: ActivatedRoute,private router: Router,private sanitizer: DomSanitizer) {
    this._coinSearchService.getRCUserProfile().then(response => {
      if (response!=null)
        this.rcUserProfile=response;
    })

   }

  renderCoin(data:any){
    //console.log(data);
    this.matchList=null;
    this.isWait=false;
    this.rotationAmount1=0;
    this.rotationAmount2=0;
    this.coinData=data;
    this.invertScans = false;
    this.coinQuality=''; 
    console.log(data);
    if (data.hasOwnProperty('coinQuality')){
      this.coinQuality=data['coinQuality']; 
    }
    this.coindate=data['date'];
    this.category=data['categoryCode'];
    this.coindiamater=data['diameter'];
    this.isTestSet=data['testset'];
    this.mintsymbol=data['mintSymbol'];
    this.coinBetaFeatures=data['coinBetaFeatures'];
    
    let tsDate=new Date(data['timestamp']);
    this.coinTimeStamp=formatDate(tsDate,'yyyy-MM-dd HH:mm','en-US');
    this.imageObverse=null;
    this.imageReverse=null;
    this.getMatchList(data);
    
    this._coinSearchService.getCoinFeaturesDef(this.category,this.coindate.toString(),this.mintsymbol).subscribe(
      data => this.renderCoinFeaturesDef(data,this.coinData),
      error => console.error('Error!', error)
    );
    this._coinSearchService.getCoinScanImage(data['customid'],'O').subscribe(
      result => {
        let objectURL = URL.createObjectURL(result);
        this.imageObverse=this.sanitizer.bypassSecurityTrustUrl(objectURL);      
      });
    this._coinSearchService.getCoinScanImage(data['customid'],'R').subscribe(
      result => {
        let objectURL = URL.createObjectURL(result);
        this.imageReverse=this.sanitizer.bypassSecurityTrustUrl(objectURL);      
      });
    this._coinSearchService.getSorterItenData(data['customid']).subscribe(
        result => {
          console.log(result);
          if ('rulesValueIndicator' in result){
            this.rulesValueIndicator=result['rulesValueIndicator'];
            // this.sorterDataArray.set(c['customid'],result);    
          }  
        });

  }

  renderCoinFeaturesDef(data:any,coinData:any) {
    this.featureList = new Map<string, string>();
    
    for (let d in data){
      let fVal='';
      if (coinData.hasOwnProperty('coinFeatures')){
        for (let key in coinData['coinFeatures']) {
          if (key==data[d]['name']){
            fVal=coinData['coinFeatures'][key];
          }
        }
      }
      this.featureList.set(data[d]['name'],fVal);
    }
    console.log(this.featureList);
    this.coinFeaturesDef=data;
  }

  changeDate(delta:number){
    this.coindate=Number(this.coindate)+Number(delta);
  }
  getMatchList(data:any){
    let searchQuery = new CoinSearchData('',data['categoryCode'],data['date'],data['mintSymbol'],false);
    this._coinSearchService.enroll(searchQuery).subscribe(
      data => this.matchListRender(data),
      error => console.error('Error!', error)
    );
  }

  postFacebook(): void {
    this._coinSearchService.facebookPostCoin(this.coinData['customid'])
      .subscribe(
        data => console.log('coin posted on facebook'),
        error => console.error('Error!', error)
      );
  }

  matchListRender(data:any){
    data.forEach((element: any) => {
      let grMinValue = -1;
      let grMinName = '';
      let grMaxValue = -1;
      let grMaxName = '';
    
      element['gradeData'].forEach((grd: [string, string]) => {
        const gradeName = grd[0];
        const gradeValue = parseFloat(grd[1]);
    
        if (!isNaN(gradeValue) && grd[1] !== '-') {
          if (grMinValue === -1 || gradeValue < grMinValue) {
            grMinName = gradeName;
            grMinValue = gradeValue;
          }
    
          if (gradeValue > grMaxValue) {
            grMaxName = gradeName;
            grMaxValue = gradeValue;
          }
        }
      });
      
      element['gradeMinValue'] = grMinValue;
      element['gradeMaxValue'] = grMaxValue;
      element['gradeMinName'] = grMinName;
      element['gradeMaxName'] = grMaxName;      
    });
    
    console.log(data);
    this.matchList=data;
  }


  // matchListRender(data:any){
  //   data.forEach(element => {
  //     let grMinValue=-1;
  //     let grMinName = '';
  //     let grMaxValue=-1;
  //     let grMaxName = '';
      //console.log(element['gradeData']);
      // element['gradeData'].forEach(grd => {
      //   if (!isNaN(grd[1]) && grd[1]!='-'){
      //     if (grMinValue==-1){
      //       grMinName=grd[0];
      //       grMinValue=grd[1];
      //     }
      //     grMaxName=grd[0];
      //     grMaxValue=grd[1];
      //   }
        
      // });
  //    
  //     element['gradeMinValue']=grMinValue;
  //     element['gradeMaxValue']=grMaxValue;
  //     element['gradeMinName']=grMinName;
  //     element['gradeMaxName']=grMaxName;      
  //   });
  //   console.log(data);
  //   this.matchList=data;
  // }

  toggleGrid(){
    this.showGrid=! this.showGrid;
  }

  toggleScale(){
    if (this.imageSize==1000){
      this.imageSize=500
    }
    else if (this.imageSize==500){
      this.imageSize=1000
    }
  }

  rotateImage(idx:number,dir:string,angle:number){
    if (idx==1){
      if (dir=='right'){
        this.rotationAmount1=this.rotationAmount1+angle;
        if (this.rotationAmount1>360){
          this.rotationAmount1=this.rotationAmount1-360
        }
      }
      else
        this.rotationAmount1=this.rotationAmount1-angle;
        if (this.rotationAmount1<0){
          this.rotationAmount1=this.rotationAmount1+360
        }
    }
    if (idx==2){
      if (dir=='right'){
        this.rotationAmount2=this.rotationAmount2+angle;
        if (this.rotationAmount2>360){
          this.rotationAmount2=this.rotationAmount2-360
        }
      }
      else
        this.rotationAmount2=this.rotationAmount2-angle;
        if (this.rotationAmount2<0){
          this.rotationAmount2=this.rotationAmount2+360
        }
    }
  }
  renderCategories(cats:any){
    this.categories=cats;
    this.nextCoinCatList=cats;
  }

  setMintSymbol(mS:string){
    this.mintsymbol=mS;
  }
  deleteCoin(){
    this._coinSearchService.deleteScans(this.customId)
    .subscribe(
      data => this.nextScanToVerify(),
      error => console.error('Error!', error)
    );
  }

  navigateToNextToV(data:any){
    console.log(data);
    
    if (data.length >0){
      this.customId=data[0]['customid'];
      this.router.navigate(['/coinscanview', data[0]['customid']]);
      this.requestCoinScanData();
    }
  }

  nextScanToVerify(){
    console.log("nextScanToVerify:"+this.nextCoinSelected);
    this._coinSearchService.getNextCoinScanToVerify(this.nextCoinSelected)
    .subscribe (
      data => this.navigateToNextToV(data),
      error => console.error('Error!', error)
    );
  }

  requestCoinScanData(){
    this._coinSearchService.getCoinScan(this.customId)
    .subscribe (
      data => {
        this.renderCoin(data);
        if (this.imgObverse && this.imgReverse && this.lens1 && this.lens2) {
          this.magnify(this.imgObverse.nativeElement, this.lens1.nativeElement);
          this.magnify(this.imgReverse.nativeElement, this.lens2.nativeElement);
        }
      },
      error => console.error('Error!', error)
    );

  }

  exportCoinScanImage(): void {
    this._coinSearchService.exportCoinScanImage(this.customId).subscribe(
      result => {
      const url = window.URL.createObjectURL(result);
      const a = document.createElement('a');
      a.href = url;
      a.download = 'image.jpg';
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
      window.URL.revokeObjectURL(url);
    });
  }


  transferToTestSet(){
    
  }

  ngOnInit(): void {
  }

  requestinData(){
    this.customId = this._Activatedroute.snapshot.paramMap.get('csid') as string;
    console.log("requesting coin scan data for:"+this.customId);
    this.requestCoinScanData();
    this._coinSearchService.getCategoryList()
    .subscribe (
      data => this.renderCategories(data),
      error => console.error('Error!', error)
    );
  }

  ngAfterViewInit(): void {
    this.requestinData();
    const source = interval(1000).pipe(startWith(0)); // fires immediately at the start, then every second
    this.subscription = source.subscribe(val => this.updateMagnifier());
  }
  updateMagnifier(): void {
    // ensure that view children are available
    if (this.imgObverse && this.imgReverse && this.lens1 && this.lens2) {
      this.magnify(this.imgObverse.nativeElement, this.lens1.nativeElement);
      this.magnify(this.imgReverse.nativeElement, this.lens2.nativeElement);
    }
  }
  
  // Don't forget to unsubscribe when the component is destroyed
  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  
  

  invertoinScans(){
    this.invertScans=!this.invertScans;
  }

  updateCoin(getNewCoin:boolean=false){
    this.isWait=true;
    this._coinSearchService.updateCoinScan(this.customId,
      this.coindate.toString(), 
      this.category,this.coindiamater.toString(),
      this.rotationAmount1,
      this.rotationAmount2,
      this.invertScans,
      this.coinQuality,
      this.isTestSet,
      this.mintsymbol,this.featureList).subscribe (
        data => {
          if (getNewCoin) {
            this.nextScanToVerify();
          }
          else
          {
            this.requestCoinScanData();
          }
        },
        error => console.error('Error!', error)
      );
  }
  magnify(img: HTMLImageElement, lens: HTMLDivElement) {
    lens.addEventListener("mousemove", moveLens);
    img.addEventListener("mousemove", moveLens);
    img.addEventListener("mouseleave", function() { lens.style.visibility = "hidden"; });  // hide the lens when mouse leaves the image
    img.addEventListener("mouseenter", function() { lens.style.visibility = "visible"; });  // show the lens when mouse enters the image
  
    const ratio = 2; // magnification ratio
    
    lens.style.backgroundImage = `url(${img.src})`;
    lens.style.backgroundRepeat = 'no-repeat';
    lens.style.backgroundSize = `${img.width * ratio}px ${img.height * ratio}px`;
  
    img.addEventListener('mousemove', (e) => {moveLens(e)});
  
    img.addEventListener('mouseout', () => {
      lens.style.visibility = 'hidden';
    });
  
    function moveLens(e: MouseEvent) {
      e.preventDefault();
      const pos = getCursorPos(e);
      // const pos2 = getRelativeMousePosition(e, img);
      // console.log(pos2);
      // calculate the position of the lens
      let x = pos.x - (lens.offsetWidth / 2);
      let y = pos.y - (lens.offsetHeight / 2);
    
      // prevent the lens from being positioned outside the image
      // if (x > img.width - lens.offsetWidth) {x = img.width - lens.offsetWidth;}
      // if (x < 0) {x = 0;}
      // if (y > img.height - lens.offsetHeight) {y = img.height - lens.offsetHeight;}
      // if (y < 0) {y = 0;}
    
      // set the position of the lens
      lens.style.left = x + "px";
      lens.style.top = y + "px";
    
      // calculate the position of the magnified image
      const magnification = 2;
      const bgX = -(x * magnification) -lens.offsetWidth/2;
      const bgY = -(y * magnification) -lens.offsetHeight/2;
      //const bgY = -(y-lens.offsetHeight/2) * magnification;
     // const bgY = -(y * magnification) + lens.offsetHeight / 2;
    
      // set the position of the magnified image
      lens.style.backgroundPosition = bgX + "px " + bgY + "px";
    }



    function getRelativeMousePosition(e: MouseEvent, img: HTMLImageElement,magRadius:number): {x: number, y: number} {
      const rect = img.getBoundingClientRect();
      const x = e.clientX - rect.left-magRadius; //x position within the image
      const y = e.clientY - rect.top-magRadius;  //y position within the image
    
      const relativeX = x / img.width;
      const relativeY = y / img.height;
    
      return {x: relativeX, y: relativeY};
    }
    function getCursorPos(e: MouseEvent) {
      const a = img.getBoundingClientRect();
      let x = e.clientX - a.left;
      let y = e.clientY - a.top;
      return {x : x, y : y};
    }
  }

  addBetaFeture(bftype:string,bfvalue:string){

  }
  

}
