import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { CoinsearchService } from '../coinsearch.service';

@Component({
  selector: 'app-coinquality',
  templateUrl: './coinquality.component.html',
  styleUrls: ['./coinquality.component.css']
})
export class CoinqualityComponent implements OnInit {
  public testData         : any  = null;
  public isWait           : boolean=false;
  public testId           : string="";
  public coinScansData    : any = [];
  public imageSizeArr     : any = [];
  public showQClassA      : boolean=true;
  public showQClassB      : boolean=true;
  public showQClassC      : boolean=true;
  public showQClassD      : boolean=true;
  public viewType         : string="ALL";
  public pageSize         : number=20;
  public currentPage      : number=0;
  public totolViewImages  : number=0;
  public displayPageItems : any=[];
 

  constructor(private _coinSearchService: CoinsearchService, private _Activatedroute: ActivatedRoute) { 
    this.initializeImageSizeArray()
  }

  initializeImageSizeArray(){
    this.imageSizeArr=[];
    for (var i=0;i<this.pageSize;i++){
      this.imageSizeArr.push('imgSMALL');
    }
  }

  toggleImageSize(idx:number){
    if (this.imageSizeArr[idx]=='imgSMALL'){
      this.imageSizeArr[idx]='imgBIG';
    }
    else{
      this.imageSizeArr[idx]='imgSMALL';
    }

  }

  changeStyle($event:any){
    if ($event.type == 'mouseover')
      return  'imgSmall';
    else
      return 'imgBig';
  }



  setQuality(customid:string, q:string){
    this._coinSearchService.updateCoinScanQuality(customid,q).subscribe(
      data => this.updateQuality(data), 
      error => console.error('Error!', error)
    );
  }

  updateQuality(data:any){
    if(data['code']=='OK'){
      var customid=data['customid'];
      var newVal=data['valuesChanged']['coinQuality'];
      for (let test of this.testData['results']){
        for (let coin of test['coins']){
          if (coin['customid']==customid){
            coin['coinQuality']=newVal;
          }
        }
      }
    }
  }

  fillDisplayPageData(){
    var data=this.filterCoins();
    this.totolViewImages=data.length;
    var counter=0;
    this.displayPageItems=[];
    for (let coin of data){
      counter+=1;
      if (counter>this.currentPage*this.pageSize&&counter<=(this.currentPage+1)*this.pageSize){
        this.displayPageItems.push(coin)
        if (this.findCoinScanData(coin['customid'])==null){
          this.requestCoinScan(coin['customid']);
        }
      };
    };
  }

  renderData(data:any){
    this.isWait=false;
    this.testData = data;
    this.fillDisplayPageData()
  }

  viewChanged(){
    this.initializeImageSizeArray();
    this.fillDisplayPageData();
  }

  changeCurrentPage(pg:number){
    this.currentPage=pg;
    this.initializeImageSizeArray();
    this.fillDisplayPageData();
  }

  getPaginatorNums(){
    var paginator=[];
    var paginatorCount=10;
    var startIdx=this.currentPage-paginatorCount/2;
    var maxIdx=Math.floor(this.totolViewImages/this.pageSize);
    if (startIdx<0){
      startIdx=0;
    }
    if (startIdx>0){
      paginator.push(0)
    }
    for (let i = startIdx; i < startIdx+paginatorCount && i<maxIdx; i++) {
      paginator.push(i)
    };
    if (paginator[paginator.length-1]<maxIdx){
      paginator.push(maxIdx);
    }
    paginator.push();
    return paginator;
  }

  coinTrackBy(index:any,item:any){
    var img="F"
    //console.log(item);
    if (item['imgO']!= undefined){
      img="T"
    }
    var rid= item['customid']+"-"+img;
    //console.log(rid);
    return rid;
  }

  filterCoin(coin:any){
    var add=false;
    if (this.showQClassA&& coin['reco_q']=='A'){
      add=true;
    }
    if (this.showQClassB&& coin['reco_q']=='B'){
      add=true;
    }
    if (this.showQClassC&& coin['reco_q']=='C'){
      add=true;
    }
    if (this.showQClassD&& coin['reco_q']=='D'){
      add=true;
    }
    if (add && this.viewType=="EMPTY"){
      if (coin['coinQuality']==undefined || coin['coinQuality'] ==''){
        add=true;
      }
      else{
        add=false;
      }
    }
    if (add && this.viewType=="LABELED"){
      if (coin['coinQuality']!=undefined && coin['coinQuality'] !=''){
        add=true;
      }
      else{
        add=false;
      }
    }
    if (add && this.viewType=="ERROR"){
      if (coin['coinQuality']!=undefined && coin['coinQuality'] !=''&& coin['coinQuality']!=coin['reco_q']){
        add=true;
      }
      else{
        add=false;
      }
    }
    return add;
  }

  filterCoins(){
    var filtered=[];
    for (let test of this.testData['results']){
      for (let coin of test['coins']){
        var add=this.filterCoin(coin);
        if(add){
          filtered.push(coin);
        }
      };
    };
    return filtered;
  }


  getCoinsToDisplay(){
    var toDispl=[];
    var counter=0;
    
    for (let test of this.testData['results']){
      for (let coin of test['coins']){
        var add=this.filterCoin(coin);
        if(add){
          counter+=1;
          if (counter>this.currentPage*this.pageSize){
            toDispl.push(coin);
          }
        }
        if(toDispl.length>=this.pageSize){
          return toDispl;
        }
        
      };
    };
    return toDispl;
  }
  findCoinScanData(id:string){
    for (let cs of this.coinScansData){
      if(cs['customid']==id){
        return cs;
      }
    }
    return null;
  }

  addCoinScanData(data:any){
    var csData = {
      'imgO'     : data['imageObverse'],
      'imgR'     : data['imageReverse'],
      'customid' : data['customid'],
      'coinQuality' : data['coinQuality']
    };

    this.coinScansData.push(csData);
    for (let test of this.testData['results']){
      for (let coin of test['coins']){
        if (data['customid']==coin['customid']){
          coin['imgO']=data['imageObverse'];
          coin['imgR']=data['imageReverse'];
          coin['coinQuality']=data['coinQuality'];
        }
      }
    }
  }

  requestCoinScan(csid:string){
    this._coinSearchService.getCoinScan(csid).subscribe(
      data => this.addCoinScanData(data), 
      error => console.error('Error!', error)
    );
  }

  requestData(tid:string){
    this.isWait=true;
    this._coinSearchService.getCoinTest(tid)
    .subscribe(
      data => this.renderData(data), 
      error => console.error('Error!', error)
    );
  }

  ngOnInit(): void {
    var testidval=this._Activatedroute.snapshot.paramMap.get('testid')
    if (testidval==null){
      testidval="";
    }
    else{
      this.testId = testidval;
    }
    this.requestData(this.testId);
  }

}
