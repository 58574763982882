import { Component, OnInit } from '@angular/core';
import { CoinsearchService,RCUserProfile } from '../coinsearch.service';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
    selector: 'app-lastscan',
    templateUrl: './lastscan.component.html',
    styleUrls: ['./lastscan.component.css'],
    standalone: false
})
export class LastscanComponent implements OnInit {
  public lastCoinScanID       : string='';
  public lastCoinScan         : any;
  public imageObverse         : any = null;
  public imageReverse         : any = null;
  public rulesValueIndicator  : any = null;
  constructor(private _coinSearchService: CoinsearchService,private sanitizer: DomSanitizer) {

  }


  checkforNewScan() {
    this._coinSearchService.getLastCoinScanId().subscribe((data: any) => {
      if (data['lastScanID']) {
        if (this.lastCoinScanID != data['lastScanID']) {
          this.lastCoinScanID = data['lastScanID'];
          this._coinSearchService.getSorterItenData(this.lastCoinScanID).subscribe(
            result => {
              console.log(result);
              this.lastCoinScan = result;
              this.updateCoinData(this.lastCoinScan.scanId);
            });
          // this._coinSearchService.getCoinScan(this.lastCoinScanID).subscribe((data: any) => {
          //   console.log(data);
          //   this.lastCoinScan = data;
          //   this.updateCoinData(this.lastCoinScan.customid)
          // });
        }        
      }
    });
  }

  updateCoinData(customid:string) {
    this._coinSearchService.getCoinScanImage(customid,'O').subscribe(
      result => {
        let objectURL = URL.createObjectURL(result);
        this.imageObverse=this.sanitizer.bypassSecurityTrustUrl(objectURL);      
      });
    this._coinSearchService.getCoinScanImage(customid,'R').subscribe(
      result => {
        let objectURL = URL.createObjectURL(result);
        this.imageReverse=this.sanitizer.bypassSecurityTrustUrl(objectURL);      
      });
      

  }

  ngOnInit() {
    setInterval(() => {
      this.checkforNewScan();
    }, 1000);
  }

}
