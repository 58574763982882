
<a mat-button class = "gap" routerLink="/search">Coin Catalog Search</a>
<mat-progress-bar mode="indeterminate" *ngIf="isWait" ></mat-progress-bar>
<div *ngIf="!isWait">
<mat-expansion-panel >
    <mat-expansion-panel-header>
      <mat-panel-title>
        Category List
      </mat-panel-title>
    </mat-expansion-panel-header>
    <table class="table stat-table">
        <tr>
          <th scope="col">#</th>
          <th scope="col">name</th>
          <th scope="col">count</th>  
          <th scope="col">diameter</th>       
          <th scope="col">value</th>      
          <th scope="col">obverse</th>
          <th scope="col">reverse</th>
        </tr>
        <tr *ngFor="let cat of jcoinDBStatus['statistics']['categories'];index as i">
            <td>{{ i + 1 }}</td>
            <td><a [routerLink]="['/search', cat['category']]">{{cat['category']}} </a></td>
            <td>{{cat['count']}}</td>
            <td>{{cat['diameter']}}</td>
            <td>{{cat['value']}}</td>
            
            <td> 
              <img *ngIf="cat['imageObverseSmall']===''" width="30px" [src]="'assets/img/nofoto_small.png'" />
              <img *ngIf="cat['imageObverseSmall']!==''" [src]="'data:image/jpg;base64,'+cat['imageObverseSmall'] | safeHtml"  width="100px"/>         
            </td>    
            <td> 
              <img *ngIf="cat['imageReverseSmall']===''" width="30px" [src]="'assets/img/nofoto_small.png'" />
              <img *ngIf="cat['imageReverseSmall']!==''" [src]="'data:image/jpg;base64,'+cat['imageReverseSmall'] | safeHtml"  width="100px"/>         
            </td>      
          
        </tr>
    </table>
</mat-expansion-panel>
             
<mat-expansion-panel>
    <mat-expansion-panel-header>
      <mat-panel-title>
        Diameter List
      </mat-panel-title>
    </mat-expansion-panel-header>
    <table class="table stat-table">
        <tr>
            <th scope="col">diameter</th>
            <th scope="col">count</th>
        </tr>
        <tr *ngFor="let cat of jcoinDBStatus['statistics']['diameters']">
            <td>{{cat['diameter']}}</td>
            <td>{{cat['count']}}</td>
        </tr>
    </table>
</mat-expansion-panel>

             
<mat-expansion-panel>
  <mat-expansion-panel-header>
    <mat-panel-title>
      ML Stats
    </mat-panel-title>
  </mat-expansion-panel-header>
  <table class="table stat-table">
      <tr>
          <th scope="col">category</th>
          <th scope="col">scans count</th>
          <th scope="col">coind db count</th>
      </tr>
      <tr *ngFor="let cat of jcoinDBStatus['mlstats']">
          <td>{{cat['categoryCode']}}</td>
          <td>{{cat['scansdb']}}</td>
          <td>{{cat['coinsdb']}}</td>
      </tr>
    </table>
    <table class="table stat-table">
      <tr *ngFor="let cat of jcoinDBStatus['mlstats']">
        <div *ngIf="cat['dates']?.length > 1">
          <td>{{cat['categoryCode']}}</td>
           
          <td *ngFor="let d of cat['dates']" >
            {{d['date']}}|{{d['scancount']}}
          </td>
        </div>
      </tr>
  </table>
</mat-expansion-panel>
</div>


