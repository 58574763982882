<h1 class="gap">Robo Coin Mobile Match:</h1>
<table>
  <!--
  <tr><td>
    <mat-form-field appearance="fill" class="gap">
      <mat-label>diameter [mm]</mat-label>
      <input matInput name = "diameter" class="form-control gap" type="number" required [(ngModel)]="diameter">
    </mat-form-field>
</td></tr>
-->
<tr><td>
    <span style="color:red;" *ngIf="message1">{{message1}}</span>
    <input #file1 type="file" hidden="true"  accept="image/*" capture="environment" (change)="preview(file1.files,'1')" />
    <button mat-flat-button color="primary" (click)="file1.click()">Scan COIN</button>
    <img [src]="imgURL1" class="gap imggap"  height="150" *ngIf="imgURL1">
</td></tr>
<tr><td>
    <span style="color:red;" *ngIf="message2">{{message2}}</span>
    <input #file2 type="file"  hidden="true"  accept="image/*" capture="environment" (change)="preview(file2.files,'2')" />
    <button mat-flat-button color="primary" (click)="file2.click()">Scan COIN</button>
    <img [src]="imgURL2" class="gap imggap"  height="150" *ngIf="imgURL2">
</td></tr>
<tr><td>
  <button [disabled]="imgURL1===null||imgURL2===null" class="btn btn-primary gap" (click)="onSubmit()">Submit</button>

</td></tr>
</table>
<mat-progress-bar mode="indeterminate" *ngIf="isWait" ></mat-progress-bar>

<div *ngIf="bestMatch!=null ">
    <h1 class="gap">Matched Coin:</h1>
    <table class="table table-striped">
      <tr>
        <td>
            <label>imageObverse</label>
            <img *ngIf="bestMatch['coin']['imageObverseSmall']===''" width="30px" [src]="'assets/img/nofoto_small.png'" />
            <img *ngIf="bestMatch['coin']['imageObverseSmall']!==''" [src]="'data:image/jpg;base64,'+bestMatch['coin']['imageObverseSmall'] | safeHtml"  width="150px"/>
        </td>

        <td>
             <label>imageReverse</label>
            <img *ngIf="bestMatch['coin']['imageReverseSmall']===''" width="30px" [src]="'assets/img/nofoto_small.png'" />
            <img *ngIf="bestMatch['coin']['imageReverseSmall']!==''" [src]="'data:image/jpg;base64,'+bestMatch['coin']['imageReverseSmall'] | safeHtml"  width="150px"/>
        </td>

          <td>
            <tr><td><a [routerLink]="['/coinview', bestMatch['coin']['rcid']]">{{bestMatch['coin']['title']}}</a></td></tr>
            <tr><td>catCode: {{ bestMatch['coin']['categoryCode'] }}<br>
                    rcid: {{ bestMatch['coin']['rcid'] }}<br>
                    <div class="cvalue">value: {{matchValue}}</div><br>
                    <div [ngClass]="{'cred' : bestMatch['score']===0,'cgreen':bestMatch['score']>0}" >match score:{{bestMatch['score']}}</div>
                    <div [ngClass]="{'cred' : bestMatch['novelty'] === true, 'cgreen': bestMatch['novelty'] === false}">novelty detection: {{bestMatch['novelty']}}</div>
                  </td></tr>
        </td>
      </tr>
      <tr>
        <h1>Normalized Input (pivot correction)</h1>
      </tr>
      <tr>
        <td>transformedImg1
            <img *ngIf="bestMatch['transformedImg1']!==''" [src]="'data:image/jpg;base64,'+bestMatch['transformedImg1'] | safeHtml"  width="150px"/>
        </td>

        <td>transformedImg2
            <img *ngIf="bestMatch['transformedImg2']!==''" [src]="'data:image/jpg;base64,'+bestMatch['transformedImg2'] | safeHtml"  width="150px"/>
        </td>
        <td></td>
        </tr>

    </table>
</div>