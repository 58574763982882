<div class="scan-container">
    <div class="header-container">
        <h1 class="header">Last Scan</h1>
        <img src="assets/img/logo2.png" alt="Logo" class="header-logo" />
    </div>
    <div *ngIf="lastCoinScanID !== ''" class="scan-details">
        <p class="scan-id">Scan ID: {{ lastCoinScanID }}</p>
        <div *ngIf="lastCoinScan !== null" class="coin-info">
            <p><b>Nominal value:</b> {{ lastCoinScan['nominalValue'] }}</p>
            <p><b>Category:</b> {{ lastCoinScan['categoryCode'] | slice:0:35 }}</p>
            <p><b>Date:</b> {{ lastCoinScan['date'] }}</p>
            <p><b>Value Level:</b> {{ lastCoinScan['rulesValueIndicator']['level'] }}</p>
            <div class="image-container">
                <img *ngIf="imageObverse !== null" class="coin-image" [src]="imageObverse" alt="Obverse Image" />
                <img *ngIf="imageReverse !== null" class="coin-image" [src]="imageReverse" alt="Reverse Image" />
            </div>
        </div>
    </div>
</div>