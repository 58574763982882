import { Component, OnInit } from '@angular/core';
import { CoinsearchService } from '../coinsearch.service';

@Component({
    selector: 'app-templates',
    templateUrl: './templates.component.html',
    styleUrls: ['./templates.component.css'],
    standalone: false
})

export class TemplatesComponent implements OnInit {
    jcoinTemplates: any=null;
    public isWait = true;

    constructor(private _coinSearchService: CoinsearchService) {
    console.log('templates status requested');
    if (this._coinSearchService.getLastCoinDBStatus() === null) {
      this._coinSearchService.getCoinTemplates()
        .subscribe(
          data => this.renderData(data),
          error => console.error('Error!', error)
        );
    } else {
      this.renderData(this._coinSearchService.getCoinTemplates());
    }
  }

  renderData(data):void {
    this.jcoinTemplates=data;
    this.isWait = false;
  }

  ngOnInit(): void {
  }

}
