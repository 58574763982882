import { Component,OnInit } from '@angular/core';
import { CoinsearchService,RCUserProfile } from '../coinsearch.service';

@Component({
    selector: 'app-sorterrules',
    templateUrl: './sorterrules.component.html',
    styleUrls: ['./sorterrules.component.css'],
    standalone: false
})
export class SorterrulesComponent implements OnInit{
  public items = [];
  public isWait:number = 0;
  public newItem: any = {};
  public newItemCF = "";
  public categories: any=null;
  public coinFeatures: any=null;
  public newDates = "";
  public newMintSymbols = "";
  public showMessage = false;
  public message:string = '';
  constructor(private _coinSearchService: CoinsearchService) {
    this.createNewItem();
   }

  ngOnInit(): void {
    this.isWait=3;

    this._coinSearchService.getSorterRules()
    .subscribe (
      data => {this.items= data;this.isWait-=1;},
      error => console.error('Error!', error)
    );

    this._coinSearchService.getCategoryList()
    .subscribe (
      data => {this.categories= data;this.isWait-=1;},
      error => console.error('Error!', error)
    ); 
    this._coinSearchService.getCoinFeaturesQueryList().subscribe(
      data => {this.coinFeatures=data;this.isWait-=1;},
      error => console.error('Error!', error)
    );
  }

  createNewItem() {
    this.newItemCF = "";
    this.newDates = "";
    this.newMintSymbols = "";
    this.newItem = {
      categoryCode: '',
      enabled: true,
      dates: [],
      mintSymbols: [],
      coinFeatures: [],
      valueIndicator: {
        level: '',
        description: ''
      },
      novelty: false
    };
  
    this.newItem;
  }
  getLevelClass(level: string): string {
    switch (level) {
      case 'LOW':
        return 'low-level';
      case 'MED':
        return 'med-level';
      case 'HIGH':
        return 'high-level';
      default:
        return '';
    }
  }

  increaseILevel(item:any){
    if (item.valueIndicator.level=='MED') {
      item.valueIndicator.level='HIGH';
    }
    if (item.valueIndicator.level=='LOW') {
      item.valueIndicator.level='MED';
    }
  }

  decreaseILevel(item:any){
    if (item.valueIndicator.level=='MED') {
      item.valueIndicator.level='LOW';
    }
    if (item.valueIndicator.level=='HIGH') {
      item.valueIndicator.level='MED';
    }
  }
  getSoringRulesCount(){
    return this.items.length;
  }

  renderDates(dates: any) {
    if (dates.length > 0) {
      return dates.join(", ");
    }
    return "";
  }

  removeItem(item: any) {
    const index = this.items.indexOf(item);
    if (index >= 0) {
      this.items.splice(index, 1);
    }
    this.addMessage('Item removed successfully.');
  }

  createCFOption(item: any) {
    return item.id + ":" + item.value;
  }

  addItem() {
    var isOK = true;
    var errorMsg = '';
    if (this.newItemCF!="") {
      this.newItem.coinFeatures.push({ name: this.newItemCF.split(":")[0], value: this.newItemCF.split(":")[1]});
    }
    if (this.newMintSymbols!="") {
      this.newMintSymbols.split(",").forEach(element => {
        const validValues = ['N', 'D', 'P', 'S', 'W'];
        if (validValues.includes(element)) {
          this.newItem.mintSymbols.push(element);
        } else {
          errorMsg='Mint ['+element+'] is not valid.';
          isOK=false;
        }
        
      });
    }
    if (this.newDates!="") {
      this.newDates.split(",").forEach(element => {
        const year = parseInt(element, 10);

        if (isNaN(year)) {
          errorMsg='Year is not a number.';
          isOK=false;
        } else if (year < 1900 || year > 2021) {
          errorMsg='Year['+element+'] is outside of range.';
          isOK=false;
        } else {
          this.newItem.dates.push(element);
        }
      });
    }
    console.log(this.newItem);
    if (!isOK) {
      alert(errorMsg);
    }
    else {
      this.items.push(this.newItem);
      this.createNewItem();
    }
    this.addMessage('Item added successfully.');
  }

  saveChanges(): void {
    this._coinSearchService.updateSorterRules(this.items).subscribe (
      data => this.addMessage('Sorter Rules saved successfully.'),
      error => this.addMessage('Error saving Sorter Rules.')
    );
  }

  addMessage(msg:string){
    this.message=msg;
    this.showMessage = true;
    setTimeout(() => {
      this.showMessage = false;
    }, 5000);
  }

  downloadJson() {
    const outData = {"rulesData":[]};
    for (let item of this.items) {
      const copiedObject = { ...item };
      delete copiedObject['_id'];
      delete copiedObject['rid'];
      delete copiedObject['userid'];

      outData.rulesData.push(copiedObject);
    }
    const jsonData = JSON.stringify(outData, null, 2);
    const blob = new Blob([jsonData], { type: 'text/plain' });
    const url= window.URL.createObjectURL(blob);
    var link = document.createElement('a');
    link.href = url;
    link.download = 'coinsearch_coinvalue_rules_default.json';
    link.click();
  }

  moveUp(item: any) {
    const index = this.items.indexOf(item);
    if (index === 0) return;
    this.items.splice(index, 1);
    this.items.splice(index - 1, 0, item);
  }

  moveDown(item: any) {
    const index = this.items.indexOf(item);
    if (index === this.items.length - 1) return; 
    this.items.splice(index, 1);
    this.items.splice(index + 1, 0, item);
  }
  getAnyObjectValue(obj:any, key:string){
    if (obj.hasOwnProperty(key)) {
      return obj[key];
    }
    return '';
  }
}
