<table class="table stat-table"> 
    <tr>
        <td>
            <mat-checkbox  name="showMintTest" [(ngModel)]="showMintTest">show MINT RECO TEST</mat-checkbox>
        </td>        
        <td>
            <mat-checkbox  name="showDateTest" [(ngModel)]="showDateTest">show DATE RECO TEST</mat-checkbox>
        </td>
        <td>
            <mat-checkbox  name="showClassTest" [(ngModel)]="showClassTest">show CLASS RECO TEST</mat-checkbox>
        </td>        
        <td>
            <mat-checkbox  name="showCutTest" [(ngModel)]="showCutTest">show COIN CUT TEST</mat-checkbox>
        </td>
        <td>
            <mat-checkbox  name="showDiameterTest" [(ngModel)]="showDiameterTest">show DIAMETER RECO TEST</mat-checkbox>
        </td>  
        <td>
            <mat-checkbox  name="showSimilarityTest" [(ngModel)]="showSimilarityTest">show SIMILARITY TEST</mat-checkbox>
        </td> 
        <td>
            <mat-checkbox  name="showCoinQualityTest" [(ngModel)]="showCoinQualityTest">show COIN Quality TEST</mat-checkbox>
        </td> 
    </tr>
</table>

<table class="table stat-table">
    <tr *ngFor="let test of testData;index as i">
        <table *ngIf="test['type']==='COINQUALITYTEST' && showCoinQualityTest" class="table stat-table">
            <tr>
                <td>{{ i + 1 }}</td>
                <td>{{test['type']}}&#64;{{test['prc']}}</td>
                <td>{{test['id']}}</td>
                <td>{{test['timestamp']}}</td>
                <td>{{test['name']}}</td>
                <td><a [routerLink]="['/coinquality', test['id']]" > view </a></td>
                <td><a href="javascript:void(0);" (click)="deleteTest(test['id'])">del</a></td>
            </tr>
        </table>
        <table *ngIf="test['type']==='COINCUT_TEST' && showCutTest" class="table stat-table">
            <mat-expansion-panel>
                <mat-expansion-panel-header>
                    <tr>
                        <td>{{ i + 1 }}</td>
                        <td>{{test['type']}}&#64;{{test['precision']}}</td>
                        <td>{{test['id']}}</td>
                        <td>{{test['timestamp']}}</td>
                        <td>{{test['name']}}</td>
                        <td><a href="javascript:void(0);" (click)="deleteTest(test['id'])">del</a></td>
                    </tr>
                </mat-expansion-panel-header>
            <tr *ngFor="let item of test['results']">
                <td>-</td>
                <td>{{item['file']}}</td>
                <td>{{item['quality']}}</td>
                <td>{{item['detected_coins']}}</td>

            </tr>
            </mat-expansion-panel>
        </table>
        <table *ngIf="(test['type']=='SIMILARITY') && showSimilarityTest" > 
            <tr  colspan="4">
                <td>{{ i + 1 }}</td>
                <td>{{test['type']}}&#64;{{test['prc']}}</td>
                <td>{{test['id']}}</td>
                <td>{{test['timestamp']}}</td>
                <td>{{test['name']}}</td>
                <td><a href="javascript:void(0);" (click)="deleteTest(test['id'])">del</a></td>
            </tr>
            <tr>
                <td colspan="6">
                    <div *ngFor="let testCode of test['results']">
                        <mat-expansion-panel>
                        <mat-expansion-panel-header>
                            <mat-panel-title>
                                {{testCode['codeName']}} {{testCode['category']}} &#64; <a [ngClass]="{'cl100': testCode['prc']==100, 'cl95': testCode['prc']>=95, 'cl90': testCode['prc']>=90, 'cl80':testCode['prc']>=80, 'cl80l': testCode['prc']<80}">{{testCode['prc']}} </a> 
                                <a *ngIf="testCode['modeldata']!== undefined" > | model timestamp: {{testCode['modeldata']['creationTime']}} crc: {{testCode['modeldata']['crc']}}</a>
                            </mat-panel-title>
                          </mat-expansion-panel-header>

                            <table class="table stat-table">
                                <tr>
                                    <td>%</td><td>{{testCode['prc']}}</td>
                                    <td>matchedS</td><td>{{testCode['matchedS']}}</td>
                                    <td>notmatchedS</td><td>{{testCode['notmatchedS']}}</td>
                                    <td>matchedD</td><td>{{testCode['matchedD']}}</td>
                                    <td>notmatchedD</td><td>{{testCode['notmatchedD']}}</td>

                                ,</tr>

                                <tr colspan="5">
                                    <td>
                                        <div *ngFor="let cs of testCode['coinidsS']">
       
                                            coinidsS[<a [routerLink]="['/coinscanview', cs['c1']]" > {{cs['c1']}} </a>
                                        
                                        <a [routerLink]="['/coinscanview', cs['c2']]" > {{cs['c2']}} </a>]
                                        </div>
                                    </td>
                                    <td>
                                        <div *ngFor="let cs of testCode['coinidsD']">
       
                                            coinidsD[<a [routerLink]="['/coinscanview', cs['c1']]" > {{cs['c1']}} </a>
                                        
                                        <a [routerLink]="['/coinscanview', cs['c2']]" > {{cs['c2']}} </a>]
                                        </div>
                                    </td>
                                </tr>

                            </table>

                        </mat-expansion-panel>
                    </div>
                </td>
            </tr>
    </table>

        <table *ngIf="(test['type']!=='COINCUT_TEST') && 
            ((test['type']=='COINPIVOTTEST')||
             (test['type']=='MINTRECOTEST' && showMintTest)  || 
             (test['type']=='DATERECOTEST'  && showDateTest) ||
             (test['type']=='CLASSRECOTEST' && showClassTest)||
             (test['type']=='DIAMETERRECOTEST' && showDiameterTest))" class="table stat-table">
        <tr  colspan="4">
            <td>{{ i + 1 }}</td>
            <td>{{test['type']}}&#64;{{test['prc']}}</td>
            <td>{{test['id']}}</td>
            <td>{{test['timestamp']}}</td>
            <td>{{test['name']}}</td>
            <td><a href="javascript:void(0);" (click)="deleteTest(test['id'])">del</a></td>
        </tr>
        <tr>
            <td colspan="6">
                <div *ngFor="let testCode of test['results']">
                    <mat-expansion-panel>
                    <mat-expansion-panel-header>
                        <mat-panel-title>
                            {{testCode['codeName']}} {{testCode['category']}} &#64; <a [ngClass]="{'cl100': testCode['prc']==100, 'cl95': testCode['prc']>=95, 'cl90': testCode['prc']>=90, 'cl80':testCode['prc']>=80, 'cl80l': testCode['prc']<80}">{{testCode['prc']}} </a> 
                            <a *ngIf="testCode['modeldata']!== undefined" > | model timestamp: {{testCode['modeldata']['creationTime']}} crc: {{testCode['modeldata']['crc']}}</a>
                        </mat-panel-title>
                      </mat-expansion-panel-header>

                        <div ng-show="testCode['type']==DATERECOTEST" >
                        <table class="table stat-table">
                            <tr *ngFor="let item of testCode['dates'] | keyvalue">
                                <!-- @ts-ignore -->
                                <td *ngIf="item.value">{{getObjectValue(item,'date')}}</td>

                                <td [ngClass]="{'cl100': getObjectValue(item,'prc')==100, 'cl95': getObjectValue(item,'prc')>=95, 'cl90': getObjectValue(item,'prc')>=90, 'cl80': getObjectValue(item,'prc')>=80, 'cl80l': getObjectValue(item,'prc')<80}" >{{getObjectValue(item,'prc')}}</td>
                                <td>{{getObjectValue(item,'matched')}}</td>
                                <td>{{getObjectValue(item,'notmatched')}}
                                    <a *ngFor="let cs of getObjectValue(item,'coinids')" [routerLink]="['/coinscanview', cs]" > {{cs}} </a>
                                </td>

                            <tr>
                        </table>
                        </div>
                        <div ng-show="testCode['type']==MINTRECOTEST" >
                            <table class="table stat-table">
                                <tr *ngFor="let item of testCode['mints'] | keyvalue">
    
                                    <td>{{getObjectValue(item,'mint')}}</td>
                                    <td [ngClass]="{'cl100': getObjectValue(item,'prc')==100, 'cl95': getObjectValue(item,'prc')>=95, 'cl90': getObjectValue(item,'prc')>=90, 'cl80': getObjectValue(item,'prc')>=80, 'cl80l': getObjectValue(item,'prc')<80}" >{{getObjectValue(item,'prc')}}</td>
                                    <td>{{getObjectValue(item,'matched')}}</td>
                                    <td>{{getObjectValue(item,'notmatched')}}
                                        <a *ngFor="let cs of getObjectValue(item,'coinids')" [routerLink]="['/coinscanview', cs]" > {{cs}} </a>
                                    </td>
    
                                <tr>
                            </table>
                            </div>
                        <div ng-show="testCode['type']==CLASSRECOTEST" >
                            <table class="table stat-table">
                                <tr>
                                    <td>matched</td><td>{{testCode['matched']}}</td>
                                    <td>notmatched</td><td>{{testCode['notmatched']}}</td>
                                    <td>
                                        <a *ngFor="let cs of testCode['coinids']" [routerLink]="['/coinscanview', cs]" > {{cs}} </a>
                                    </td>
                                
                                </tr>
                            </table>
                        </div>
                    </mat-expansion-panel>
                </div>
            </td>
        </tr>
    </table>
    </tr>
    
</table>
