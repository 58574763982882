<h1 class="gap">Robo Coin Scan Tasks:</h1>
<table>

<tr><td>
  <button  class="btn btn-primary" (click)="requestTasks()">reload</button>
</td></tr>
</table>
<mat-progress-bar mode="indeterminate" *ngIf="isWait"></mat-progress-bar>
<table class="table stat-table">
  <tr>
      <td *ngIf="coinsScansPage!==0">
          <a href="javascript:void(0);" (click)="loadPage(0)">First</a>
      </td>
      <td *ngFor="let page of pages">
          
          <a *ngIf="page!==coinsScansPage" href="javascript:void(0);" (click)="loadPage(page)">{{page}}</a>
          <a *ngIf="page===coinsScansPage" >[{{page}}]</a>
          
      </td>
  </tr>
</table>
<table class="table stat-table">
  <tr *ngFor="let scantask of taskData;index as i">
    <td>
    <table class='taskinfo'>
      <tr>
        <td>{{ coinsScansPage*coinsPerPage + i + 1 }}</td>
        <td><button  class="btn btn-primary" (click)="resetTask(scantask['customid'])">reset Task</button></td>
        <td>{{scantask['status']}}</td>
        <td><a [routerLink]="['/scantaskview', scantask['customid']]">{{scantask['customid']}} </a>          {{scantask['scanGroup']}}</td>
        <td>{{scantask['timestamp']}}</td>
        <td>{{scantask['user']}}@{{scantask['owner']}}</td>

        <td>{{scantask['coinCount']}}</td>
        <td>
          <img *ngIf="scantask['flipVertical']===true" src="assets/img/flip_v.png" width="20px"/>
          <img *ngIf="scantask['flipVertical']===false" src="assets/img/flip_v_not.png" width="20px"/>
        </td> 
        <td>
          <img *ngIf="scantask['flipHorizontal']===true" src="assets/img/flip_h.png" width="20px"/>
          <img *ngIf="scantask['flipHorizontal']===false" src="assets/img/flip_h_not.png" width="20px"/>
        </td> 
        <td>
          <img *ngIf="scantask['flipMode']==='manual'" src="assets/img/flip_manual.png" width="20px"/>
          <img *ngIf="scantask['flipMode']==='auto'" src="assets/img/flip_auto.png" width="20px"/>
        </td> 

      </tr>
    </table>
      <table>
      <tr *ngFor="let scancoin of scantask['coinscans'];index as t" class='cointaskinfo'>
        <td class='cointaskinfo'>></td>
        
        <td class='cointaskinfo'>{{t+1}}</td>
        <td class='cointaskinfo'>-</td>
        <td class='cointaskinfo'> <a [routerLink]="['/coinscanview', scancoin]">{{scancoin}}</a> </td>

      </tr>

    </table>
  
    </td>
    <td>
      <img *ngIf="scantask['imageSide1']===''" width="30px" [src]="'assets/img/nofoto_small.png'" />
      <a *ngIf="scantask['imageSide1']!==''"  class="download_btn" href="javascript:void(0);" (click)="saveImage(scantask['imageSide1'])">
        <img *ngIf="scantask['imageSide1']!==''" [src]="'data:image/jpg;base64,'+scantask['imageSide1'] | safeHtml"  width="200px"/>
      </a>
    </td>
    <td>
      <img *ngIf="scantask['imageSide2']===''" width="30px" [src]="'assets/img/nofoto_small.png'" />
      <a *ngIf="scantask['imageSide1']!==''"  class="download_btn" href="javascript:void(0);" (click)="saveImage(scantask['imageSide2'])">
        <img *ngIf="scantask['imageSide2']!==''" [src]="'data:image/jpg;base64,'+scantask['imageSide2'] | safeHtml"  width="200px"/>
      </a>
    </td>

    <td>
      <a  href="javascript:void(0);" (click)="deleteScanTask(scantask['customid'])">del</a>
    </td>


  </tr>
  
</table>
<mat-progress-bar mode="indeterminate" *ngIf="isWait" ></mat-progress-bar>

