import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { CoinsearchService } from '../coinsearch.service';

@Component({
  selector: 'app-scan-task-view',
  templateUrl: './scan-task-view.component.html',
  styleUrls: ['./scan-task-view.component.css']
})
export class ScanTaskViewComponent implements OnInit {
  public taskData: any = null;

  constructor(private _coinSearchService: CoinsearchService, private _Activatedroute: ActivatedRoute) { }

  ngOnInit(): void {
    const tid = this._Activatedroute.snapshot.paramMap.get('tid');
    if (tid === null) {
      return;
    }
    this._coinSearchService.getCoinTask(tid)
    .subscribe (
      data => this.renderTask(data),
      error => console.error('Error!', error)
    );
  }

  convertBase64ToBlobData(base64Data: string, contentType: string='image/png', sliceSize=512) {
    const byteCharacters = atob(base64Data);
    const byteArrays = [];

    for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
      const slice = byteCharacters.slice(offset, offset + sliceSize);

      const byteNumbers = new Array(slice.length);
      for (let i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i);
      }

      const byteArray = new Uint8Array(byteNumbers);

      byteArrays.push(byteArray);
    }

    const blob = new Blob(byteArrays, { type: contentType });
    return blob;
  } 

  saveImage(base64content:string){
    let filename='taskscan.png';
    const blobData = this.convertBase64ToBlobData(base64content);

    const nav = (window.navigator as any);
    if (nav.msSaveOrOpenBlob) {
      nav.msSaveOrOpenBlob(blobData, filename);
    // }

    // if (window.navigator && window.navigator.msSaveOrOpenBlob) { //IE
    //   window.navigator.msSaveOrOpenBlob(blobData, filename);
    } else { // chrome
      const blob = new Blob([blobData], { type: 'image/png' });
      const url = window.URL.createObjectURL(blob);
      // window.open(url);
      const link = document.createElement('a');
      link.href = url;
      link.download = filename;
      link.click();
    }
  } 

  renderTask(data:any){
    this.taskData=data;
  }

}
