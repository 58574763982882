import { Component, OnInit } from '@angular/core';
import {CoinsearchService} from '../coinsearch.service';

@Component({
    selector: 'app-scantasks',
    templateUrl: './scantasks.component.html',
    styleUrls: ['./scantasks.component.css'],
    standalone: false
})
export class ScantasksComponent implements OnInit {
  public imagePath1='';
  public imgURL1: any= null;
  public message1: string='';

  public imagePath2=  '';
  public imgURL2: any= null;
  public message2: string='';
  public isWait = false;
  public coinScansCount     : number = 0;
  public coinsScansPage     : number = 0;
  public coinsPerPage       : number = 5;
  public pages              : number[]= [];
  public taskData: any=null;

  constructor(private _coinSearchService: CoinsearchService) {
    this.requestTasks();
   }


  calculatePages(data:any){
    this.pages=[];
    let start:number = this.coinsScansPage - 5;
    if (start<0){
      start=0;
    }
    for (let i = start; i < start+10; i++) {
      this.pages.push(i)
    }
    console.log(this.pages)
  }
  renderData(data:any){
    console.log(data);
    this.calculatePages(data);
    this.taskData=data;
    this.isWait=false;
  }


  deleteScanTask(taskId:string){
    this, this._coinSearchService.deleteScanTasks(taskId)
    .subscribe(
      data => this.requestTasks(),
      error => console.error('Error!', error)
    );

  }

  resetTask(taskId:string){
    this, this._coinSearchService.resetScanTaskStatus(taskId)
    .subscribe(
      data => this.requestTasks(),
      error => console.error('Error!', error)
    );

  }


  requestTasks(){
    this.isWait = true;
    this, this._coinSearchService.getScanTasks((this.coinsScansPage*this.coinsPerPage).toString(),this.coinsPerPage.toString())
    .subscribe(
      data => this.renderData(data),
      error => console.error('Error!', error)
    );
    this.imgURL1=''
    this.imgURL2=''
  }

  ngOnInit(): void {
  }

  loadPage(pageNum:number){
    this.coinsScansPage=pageNum;
    this.requestTasks();
  }
  


  preview(files:any, tag:string) {
    if (files.length === 0) {
      return;
    }

    const mimeType = files[0].type;
    if (mimeType.match(/image\/*/) == null) {
      this.message1 = 'Only PNG images are supported.';
      return;
    }

    const reader = new FileReader();

    if (tag === '1') {
      //console.log(files);
      this.imagePath1 = files;
      
      reader.onload = (_event) => {
        this.imgURL1 = reader.result;
      };
      reader.readAsDataURL(files[0]);
    }
    if (tag === '2') {
      //console.log(files);
      this.imagePath2 = files;
     
      reader.onload = (_event) => {
        this.imgURL2 = reader.result;

      };
      reader.readAsDataURL(files[0]);
    }
  }

  convertBase64ToBlobData(base64Data: string, contentType: string='image/png', sliceSize=512) {
    const byteCharacters = atob(base64Data);
    const byteArrays = [];

    for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
      const slice = byteCharacters.slice(offset, offset + sliceSize);

      const byteNumbers = new Array(slice.length);
      for (let i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i);
      }

      const byteArray = new Uint8Array(byteNumbers);

      byteArrays.push(byteArray);
    }

    const blob = new Blob(byteArrays, { type: contentType });
    return blob;
  }

  saveImage(base64content:string){
    let filename='taskscan.png';
    const blobData = this.convertBase64ToBlobData(base64content);
    // if (window.navigator && window.navigator.msSaveOrOpenBlob) { //IE
    //   window.navigator.msSaveOrOpenBlob(blobData, filename);
    const nav = (window.navigator as any);
    if (nav.msSaveOrOpenBlob) {
      nav.msSaveOrOpenBlob(blobData, filename);

    } else { // chrome
      const blob = new Blob([blobData], { type: 'image/png' });
      const url = window.URL.createObjectURL(blob);
      // window.open(url);
      const link = document.createElement('a');
      link.href = url;
      link.download = filename;
      link.click();
    }
  }




}
