import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { CoinsearchService } from '../coinsearch.service';

@Component({
  selector: 'app-coinview',
  templateUrl: './coinview.component.html',
  styleUrls: ['./coinview.component.css']
})
export class CoinviewComponent implements OnInit {
  public coinData: any = null;
  public message1:string='';
  public message2:string='';
  public imagePath1:any = null;
  public imagePath2:any = null;

  public imgURL1:any = null;
  public imgURL2:any = null;
  public copyfromcoin:string = '';

  constructor(private _coinSearchService: CoinsearchService, private _Activatedroute: ActivatedRoute) { }

  renderCoin(data:any): void {
    console.log(data);
    // link cleanup hack
    let linkstr = data.link;
    const dl = linkstr.length;
    console.log('sub: [' + dl + ']' + linkstr.substring(dl - 5, dl));
    if (linkstr.substring(dl - 5, dl) === '.html') {
      linkstr = linkstr.substring(0, dl - 5);
    }
    if (linkstr.substring(0, 1) === '/') {
      linkstr = linkstr.substring(1, linkstr.length);
    }
    console.log(linkstr);
    data.link = linkstr;
    this.coinData = data;
  }

  preview(files:any, tag:string) {
    if (files.length === 0) {
      return;
    }

    const mimeType = files[0].type;
    if (mimeType.match(/image\/*/) == null) {
      this.message1 = 'Only PNG images are supported.';
      return;
    }

    const reader = new FileReader();

    if (tag === '1') {
      //console.log(files);
      this.imagePath1 = files;
      reader.readAsDataURL(files[0]);
      reader.onload = (_event) => {
        this.imgURL1 = reader.result as string;
      };
    }
    if (tag === '2') {
      //console.log(files);
      this.imagePath2 = files;
      reader.readAsDataURL(files[0]);
      reader.onload = (_event) => {
        this.imgURL2 = reader.result as string;

      };
    }
  }



  invertCoinSides(): void {
    this, this._coinSearchService.invertCoinSide(this.coinData['rcid'])
      .subscribe(
        data => this.renderCoin(data),
        error => console.error('Error!', error)
      );
  }

  saveImages(): void {
    if (this.copyfromcoin!=''){
      //empty file
      var emptyFile = new File([""], "emptyFile");
      this, this._coinSearchService.uploadCoinImages(emptyFile ,emptyFile,this.coinData['rcid'],this.copyfromcoin.trim())
      .subscribe(
        data => this.renderCoin(data),
        error => console.error('Error!', error)
      );
    }
    else{

      this, this._coinSearchService.uploadCoinImages(this.imgURL1,this.imgURL2,this.coinData['rcid'],'')
      .subscribe(
        data => this.renderCoin(data),
        error => console.error('Error!', error)
      );
    
    }
  }

  ngOnInit(): void {
    const crid = this._Activatedroute.snapshot.paramMap.get('crid');
    if (crid === null) {
      return;
    }
    this._coinSearchService.getCoin(crid)
    .subscribe (
      data => this.renderCoin(data),
      error => console.error('Error!', error)
    );
  }

}
