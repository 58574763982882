
<div *ngIf="coinData!=null">

<table class="table table-striped">
    <tr>
      <th scope="col">name</th>
      <th scope="col">value</th>

    </tr>
    <tr >
      <td>uidNGC</td>
      <td>{{ coinData['uidNGC'] }}</td>
    </tr>
    <tr >
      <td>rcid</td>
      <td>{{ coinData['rcid'] }}</td>
    </tr>
    <tr >
      <td>title</td>
      <td>{{ coinData['title'] }}</td>
    </tr>
    <tr >
        <td>value</td>
        <td>{{ coinData['value'] }}</td>
      </tr>
      <tr >
        <td>date</td>
        <td>{{ coinData['date'] }}</td>
      </tr>
    <tr>
        <td>category</td>
        <td><a [routerLink]="['/search', coinData['categoryCode']]">{{ coinData['category'] }} </a></td>
      </tr>
      <tr >
        <td>categoryCode</td>
        <td>{{ coinData['categoryCode'] }}</td>
      </tr>
      <tr >
        <td>diameter</td>
        <td>{{ coinData['diameter'] }}</td>
      </tr>
      <tr >
        <td>link</td>
        <td><a href="http://{{ coinData['link'] }}" target="_blank">{{ coinData['link'] }}</a></td>
      </tr>

      <tr >
        <td>weight</td>
        <td>{{ coinData['weight'] }}</td>
      </tr>
      <tr >
        <td>mintage</td>
        <td>{{ coinData['mintage'] }}</td>
      </tr>
      <tr >
        <td>mint</td>
        <td>{{ coinData['mint'] }}</td>
      </tr>
      <tr>
        <td>mintSymbol</td>
        <td>{{ coinData['mintSymbol'] }}</td>
      </tr>
      <tr>
        <td>Proof</td>
        <td>{{ coinData['proof'] }}</td>
      </tr>
      <tr>
        <td>SMS</td>
        <td>{{ coinData['sms'] }}</td>
      </tr>
      <tr >
        <td>composition</td>
        <td>{{ coinData['composition'] }}</td>
      </tr>
      <tr >
        <td>edge</td>
        <td>{{ coinData['edge'] }}</td>
      </tr>
      <tr >
        <td>
          
            <img *ngIf="coinData['imageObverseSmall']===''" width="30px" [src]="'assets/img/nofoto_small.png'" />
            <img *ngIf="coinData['imageObverseSmall']!==''" [src]="'data:image/jpg;base64,'+coinData['imageObverseSmall'] | safeHtml"  width="150px"/>
        </td>
    
        <td>
            <img *ngIf="coinData['imageReverseSmall']===''" width="30px" [src]="'assets/img/nofoto_small.png'" />
            <img *ngIf="coinData['imageReverseSmall']!==''" [src]="'data:image/jpg;base64,'+coinData['imageReverseSmall'] | safeHtml"  width="150px"/>
        </td>
        <td>
          <span style="color:red;" *ngIf="message1">{{message1}}</span>
          <input #file1 type="file" class="gap imggap" accept='image/png' (change)="preview(file1.files,'1')" />
          <img [src]="imgURL1" class="gap imggap"  height="150" *ngIf="imgURL1">
        </td>
        <td>
          <span style="color:red;" *ngIf="message2">{{message2}}</span>
          <input #file2 type="file" class="gap imggap"  accept='image/png' (change)="preview(file2.files,'2')" />
          <img [src]="imgURL2" class="gap imggap"  height="150" *ngIf="imgURL2">
        </td>
        <td>
          <mat-form-field >
            <mat-label>copyfrom scan coin</mat-label>
            <input matInput name="copyfromcoin" [(ngModel)]="copyfromcoin">
        </mat-form-field>
          <button class="btn btn-primary gap" (click)="invertCoinSides()">invert sides</button>
          <button class="btn btn-primary gap" (click)="saveImages()">save images</button>
        </td>
      </tr>
    </table>
    <table class="table table-bordered">
      <tr>
        <th *ngFor="let grd of coinData['gradeData'] ;index as i" scope="col">{{grd[0]}}</th>
      </tr>
      <tr>
        <td *ngFor="let grd of coinData['gradeData'] ;index as i">
         
            <tr>{{grd[1]}}</tr>
          
        </td>
      </tr>
    </table>

    </div>
    <div *ngIf="coinData!=null">
    <table>
      
      <tr>
        <td>imageObverse
          
            <img *ngIf="coinData['imageObverse']===''" width="30px" [src]="'assets/img/nofoto_small.png'" />
            <img *ngIf="coinData['imageObverse']!==''" [src]="'data:image/jpg;base64,'+coinData['imageObverse'] | safeHtml"  />
        </td>
        </tr>
        <tr>
            <td>imageReverse
                <img *ngIf="coinData['imageReverse']===''" width="30px" [src]="'assets/img/nofoto_small.png'" />
                <img *ngIf="coinData['imageReverse']!==''" [src]="'data:image/jpg;base64,'+coinData['imageReverse'] | safeHtml" />
            </td>
        </tr>
  </table>
</div>
