import { Injectable } from '@angular/core';
import { HttpClient, HttpParams  } from '@angular/common/http';
import { CoinSearchData } from './coinsearch-form/datamodels';
import { KeycloakService } from 'keycloak-angular';
import { KeycloakProfile } from 'keycloak-js';


export class RCUserProfile {
  public userName: string='';
  public isLoggedIn: boolean=false;
  public isAdmin: boolean=false;
}

@Injectable({
  providedIn: 'root'
})



export class CoinsearchService {
  //_baseFlaskService       = window.location.hostname;
  public userProfile: KeycloakProfile | null = null;
  rcUserProfile: RCUserProfile| null = null;
  
  _baseFlaskServicePort         = 443;
  _baseFlaskService             = ""//RCAPISERVER_URL
  _baseKeycloakService          = ""//KEYCLOAK_TCP_ADDR
  _baseWorkerService            = "";//ROBOCOIN_WORKER_TCP_ADDR
  _urlSearch                    = this._baseFlaskService+':'+this._baseFlaskServicePort+'/coinsearch';
  _urlDBStatus                  = this._baseFlaskService+':'+this._baseFlaskServicePort+'/dbstatus';
  _urlMatchcoin                 = this._baseFlaskService+':'+this._baseFlaskServicePort+'/coinmatch';
  _urlMatchcoin2                = this._baseFlaskService+':'+this._baseFlaskServicePort+'/coinmatch2';
  _urlRecognizeCoin             = this._baseFlaskService+':'+this._baseFlaskServicePort+'/coinrecognize';
  _urlGetCoin                   = this._baseFlaskService+':'+this._baseFlaskServicePort+'/getcoin';
  _urlInvertCoin                = this._baseFlaskService+':'+this._baseFlaskServicePort+'/invertcoin';
  _urlGetTemplates              = this._baseFlaskService+':'+this._baseFlaskServicePort+'/templates';
  _urlGetTemplate               = this._baseFlaskService+':'+this._baseFlaskServicePort+'/gettemplate';
  _urlUploadCoinImg             = this._baseFlaskService+':'+this._baseFlaskServicePort+'/uploadcoinimages';
  _urlGetCoinScans              = this._baseFlaskService+':'+this._baseFlaskServicePort+'/getcoinscans';
  _urlGetLastCoinScanID         = this._baseFlaskService+':'+this._baseFlaskServicePort+'/getlastcoinscanid'; 
  _urlGetCoinScansValue         = this._baseFlaskService+':'+this._baseFlaskServicePort+'/getcoinscansvalue';
  _urlDeleteCoinScan            = this._baseFlaskService+':'+this._baseFlaskServicePort+'/deletecoinscan';
  _urlGetCoinScan               = this._baseFlaskService+':'+this._baseFlaskServicePort+'/getcoinscan';
  _urlGetCoinScanImage          = this._baseFlaskService+':'+this._baseFlaskServicePort+'/getcoinscansimage';
  _urlGetCoinFeaturesDef        = this._baseFlaskService+':'+this._baseFlaskServicePort+'/getcoinfeaturesdef';
  _urlGetCoinFeaturesQueryList  = this._baseFlaskService+':'+this._baseFlaskServicePort+'/getcoinfeaturesquerylist';
  _urlNextCoinScanToV           = this._baseFlaskService+':'+this._baseFlaskServicePort+'/getnetxcoinscantoverify';
  _urlCoinScansStats            = this._baseFlaskService+':'+this._baseFlaskServicePort+'/getcoinscansstats';
  _urlGetCategories             = this._baseFlaskService+':'+this._baseFlaskServicePort+'/getcategories';
  _urlUpdateCoinScan            = this._baseFlaskService+':'+this._baseFlaskServicePort+'/updatecoinscan';
  _urlUpdatFieldeCoinScan       = this._baseFlaskService+':'+this._baseFlaskServicePort+'/updatecoinscanfield';
  _urlGetAllCoinTests           = this._baseFlaskService+':'+this._baseFlaskServicePort+'/gettests';
  _urlGetCoinTest               = this._baseFlaskService+':'+this._baseFlaskServicePort+'/gettest';  
  _urlDeleteCoinTest            = this._baseFlaskService+':'+this._baseFlaskServicePort+'/deletetests';
  _urlGetScanTasks              = this._baseFlaskService+':'+this._baseFlaskServicePort+'/getscantasks';
  _urlGetScanTask               = this._baseFlaskService+':'+this._baseFlaskServicePort+'/getscantask';
  _urlInsertScanTask            = this._baseFlaskService+':'+this._baseFlaskServicePort+'/insertscantask';
  _urlDeleteScanTask            = this._baseFlaskService+':'+this._baseFlaskServicePort+'/deletescantask';
  _urlGetScanTaskStat           = this._baseFlaskService+':'+this._baseFlaskServicePort+'/getscantaskstatus';
  _urlResetScanTaskStat         = this._baseFlaskService+':'+this._baseFlaskServicePort+'/resetscantaskstatus';
  //SORTER
  _urlSorterProcess             = this._baseFlaskService+':'+this._baseFlaskServicePort+'/sorterprocess';
  _urlSorterProcess2            = this._baseFlaskService+':'+this._baseFlaskServicePort+'/sorterprocess3';
  _urlSorterGetList             = this._baseFlaskService+':'+this._baseFlaskServicePort+'/getsortertaskdatalist';
  _urlSorterTaskData            = this._baseFlaskService+':'+this._baseFlaskServicePort+'/getsortertaskdata';
  _urlDeleteSorterTask          = this._baseFlaskService+':'+this._baseFlaskServicePort+'/deletesortertaskdata';
  _urlSorterItem                = this._baseFlaskService+':'+this._baseFlaskServicePort+'/getsorteritem';
  //SORTERRULES
  _urlSorterRulesReadAll        =  this._baseFlaskService+':'+this._baseFlaskServicePort+'/sorterrules_read_all_user_items';
  _urlSorterRulesUpdateAll      =  this._baseFlaskService+':'+this._baseFlaskServicePort+'/sorterrules_update_all_user_items';
  _urlSorterRulesDeleteAll      =  this._baseFlaskService+':'+this._baseFlaskServicePort+'/sorterrules_delete_all_user_items';
  //TOOLS
  _urlCopyExternalCSS           = this._baseFlaskService+':'+this._baseFlaskServicePort+'/copyexternalcss';
  //FACEBOOK/EXPORT
  _urlFacebookPostCoin          = this._baseFlaskService+':'+this._baseFlaskServicePort+'/facebookpostcoin';
  _urlExportCoinScanIMG         = this._baseFlaskService+':'+this._baseFlaskServicePort+'/exportcoinimages';
  //PROFILE
  _urlGetUserProfile           = this._baseFlaskService+':'+this._baseFlaskServicePort+'/getuserprofile';
  _urlLogoutUserSession        = this._baseFlaskService+':'+this._baseFlaskServicePort+'/logoutusersession';
  _urlConnectScanner           = this._baseFlaskService+':'+this._baseFlaskServicePort+'/connectscanner';

  fillUrls(){
  //   var urlbase=this._baseFlaskService+':'+this._baseFlaskServicePort+'/api';
  // if (this._baseFlaskService.startsWith('http://')){
  //   urlbase=this._baseFlaskService;
  // }
  var urlbase=this._baseFlaskService;
  this._urlSearch                    = urlbase+'/coinsearch';
  this._urlDBStatus                  = urlbase+'/dbstatus';
  this._urlMatchcoin                 = urlbase+'/coinmatch';
  this._urlMatchcoin2                = urlbase+'/coinmatch2';
  this._urlRecognizeCoin             = urlbase+'/coinrecognize';
  this._urlGetCoin                   = urlbase+'/getcoin';
  this._urlInvertCoin                = urlbase+'/invertcoin';
  this._urlGetTemplates              = urlbase+'/templates';
  this._urlGetTemplate               = urlbase+'/gettemplate';
  this._urlUploadCoinImg             = urlbase+'/uploadcoinimages';
  this._urlGetCoinScans              = urlbase+'/getcoinscans';
  this._urlGetLastCoinScanID         = urlbase+'/getlastcoinscanid';
  this._urlGetCoinScansValue         = urlbase+'/getcoinscansvalue';
  this._urlDeleteCoinScan            = urlbase+'/deletecoinscan';
  this._urlGetCoinScan               = urlbase+'/getcoinscan';
  this._urlGetCoinScanImage          = urlbase+'/getcoinscansimage';
  this._urlGetCoinFeaturesDef        = urlbase+'/getcoinfeaturesdef';
  this._urlGetCoinFeaturesQueryList  = urlbase+'/getcoinfeaturesquerylist';
  this._urlNextCoinScanToV           = urlbase+'/getnetxcoinscantoverify';
  this._urlCoinScansStats            = urlbase+'/getcoinscansstats';
  this._urlGetCategories             = urlbase+'/getcategories';
  this._urlUpdateCoinScan            = urlbase+'/updatecoinscan';
  this._urlUpdatFieldeCoinScan       = urlbase+'/updatecoinscanfield';
  this._urlGetAllCoinTests           = urlbase+'/gettests';
  this._urlGetCoinTest               = urlbase+'/gettest';  
  this._urlDeleteCoinTest            = urlbase+'/deletetests';
  this._urlGetScanTasks              = urlbase+'/getscantasks';
  this._urlGetScanTask               = urlbase+'/getscantask';
  this._urlInsertScanTask            = urlbase+'/insertscantask';
  this._urlDeleteScanTask            = urlbase+'/deletescantask';
  this._urlGetScanTaskStat           = urlbase+'/getscantaskstatus';
  this._urlResetScanTaskStat         = urlbase+'/resetscantaskstatus';
  //SORTER
  this._urlSorterProcess             = urlbase+'/sorterprocess';
  this._urlSorterProcess2            = this._baseWorkerService+'/sorterprocess3';
  this._urlSorterGetList             = urlbase+'/getsortertaskdatalist';
  this._urlSorterTaskData            = urlbase+'/getsortertaskdata';
  this._urlDeleteSorterTask          = urlbase+'/deletesortertaskdata';
  this._urlSorterItem                = urlbase+'/getsorteritem';
  //SORTERRULES
  this._urlSorterRulesReadAll        =  urlbase+'/sorterrules_read_all_user_items';
  this._urlSorterRulesUpdateAll      =  urlbase+'/sorterrules_update_all_user_items';
  this._urlSorterRulesDeleteAll      =  urlbase+'/sorterrules_delete_all_user_items';
  //TOOLS
  this._urlCopyExternalCSS           = urlbase+'/copyexternalcss';
  //FACEBOOK/EXPORT
  this._urlFacebookPostCoin          = urlbase+'/facebookpostcoin';
  this._urlExportCoinScanIMG         = urlbase+'/exportcoinimages';  
  //PROFILE
  this._urlGetUserProfile           = urlbase+'/getuserprofile';
  this._urlLogoutUserSession        = urlbase+'/logoutusersession';
  this._urlConnectScanner           = urlbase+'/connectscanner';  
  
  }


  private lastQueryResult: any = null;
  private lastQueryParams: CoinSearchData = new CoinSearchData('', '','','',false);
  private lastSearchPage = 0;
  private lastDBStatus: any = null;
  private userId = 'WEBADMINUSER';
  private initialized = false;

  constructor(private _http: HttpClient,private readonly keycloak: KeycloakService) { 
    this.checkConfigFile();
  }

  //PROFILE-----------------------------------------------------
  getUserProfileData(){
    this.checkConfigFile();
    return this._http.get<any>(this._urlGetUserProfile,{}); 
  }

  logoutUserSession(session_id:string,client_id:string){
    this.checkConfigFile();
    return this._http.get<any>(this._urlLogoutUserSession,{ params: new HttpParams()
      .set('session_id', session_id)
      .set('client_id', client_id)
    }); 
  }

  connectScanner(auth_code:string){
    this.checkConfigFile();
    return this._http.get<any>(this._urlConnectScanner,{ params: new HttpParams()
      .set('auth_code', auth_code)
    }); 
  }

  getKeycloakURI(){
    this.checkConfigFile();
    return this._baseKeycloakService;
  }

  checkConfigFile() {
      this._baseFlaskService = "https://" + window.location.hostname;

      // Check if already initialized
      if (this.initialized === true) {
          return;
      }


      try {
        //get file from server
        var xhr = new XMLHttpRequest();
        xhr.open('GET', '/assets/config.json', false);
        xhr.send(null);
        if (xhr.status === 200) {
          var config = JSON.parse(xhr.responseText);
          if (config.KEYCLOAK_TCP_ADDR !== undefined) {
            this._baseKeycloakService = config.KEYCLOAK_TCP_ADDR;
          }
          if (config.RCAPISERVER_URL !== undefined) {
            this._baseFlaskService = config.RCAPISERVER_URL;
            this._baseWorkerService = config.ROBOCOIN_WORKER_URL;
            this.initialized = true;
            this.fillUrls();
          }

        }

      } catch (error) {
          console.error('Error loading config file', error);
          // Handle error or return something meaningful
          return null;
      }
  }



  enroll(queryCoin: CoinSearchData ) {
    this.checkConfigFile();
    this.lastQueryParams = queryCoin;
    return this._http.get<any>(this._urlSearch, {
        params: new HttpParams().set('coinNID', queryCoin.queryNID)
          .set('categoryID', queryCoin.categoryID)
          .set('showCollectors', String(queryCoin.showCollectors))
          .set('mintSymbol', queryCoin.mintSymbol)
          .set('date', queryCoin.date)
    });
  }

   getLastQueryResult() {
    return this.lastQueryResult;
  }

  setLastQueryResult(data: any) {
    this.lastQueryResult = data;
  }

  getLastSearchPage() {
    return this.lastSearchPage;
  }

  setLastSearchPage(pg: number) {
    this.lastSearchPage = pg;
  }

  getLastQueryParams() {
    return this.lastQueryParams;
  }

  getLastCoinDBStatus() {
    return this.lastDBStatus;
  }

  setLastCoinDBStatus(data: any) {
    this.lastDBStatus = data;
  }

  getCoinDBStatus(){
    this.checkConfigFile();
    return this._http.get<any>(this._urlDBStatus, { });
  }

  getCoinTemplates() {
    this.checkConfigFile();
    return this._http.get<any>(this._urlGetTemplates, { });
  }

  getCoin(rcid: string) {
    this.checkConfigFile();
    return this._http.get<any>(this._urlGetCoin, {
      params: new HttpParams().set('rcid', rcid)
    });
  }

  invertCoinSide(rcid:string){
    this.checkConfigFile();
    return this._http.get<any>(this._urlInvertCoin, {
      params: new HttpParams().set('rcid', rcid)
    });
    
  }

  getTemplate(tid:string){
    this.checkConfigFile();
    return this._http.get<any>(this._urlGetTemplate, {
      params: new HttpParams().set('tid', tid)
    }); 
  }

  recognizeCoin(image1: File, image2: File, isDebug: boolean) {
    this.checkConfigFile();
    const formData = new FormData();
    formData.append('image1File', image1, '1');
    formData.append('image2File', image2, '2');
    formData.append('isdebug', String(isDebug));
    return this._http.post<any>(this._urlRecognizeCoin, formData );
  }

  matchCoins(image1: File, image2: File, diameter: Number, isDebug: boolean,isDirty: boolean, limitRCIDList: string) {
    this.checkConfigFile();
    const formData = new FormData();
    formData.append('image1File', image1, '1');
    formData.append('image2File', image2, '2');
    formData.append('diameter', String(diameter));
    formData.append('isdebug', String(isDebug));
    formData.append('isdirty', String(isDirty));
    formData.append('limitrcidlist', limitRCIDList);

    return this._http.post<any>(this._urlMatchcoin, formData );
  }

  matchCoins2(image1: File, image2: File, diameter: Number, isDebug: boolean,isDirty: boolean,noImage: boolean) {
    this.checkConfigFile();
    const formData = new FormData();
    formData.append('image1File', image1, '1');
    formData.append('image2File', image2, '2');
    formData.append('diameter', String(diameter));
    formData.append('isdebug', String(isDebug));
    formData.append('noimages', String(noImage));
    formData.append('isdirty', String(isDirty));
    return this._http.post<any>(this._urlMatchcoin2, formData );
  }
  

  uploadCoinImages(image1: File, image2: File,rcid:String,copyfrom:String){
    this.checkConfigFile();
    const formData = new FormData();
    formData.append('imageObverse', image1);
    formData.append('imageReverse', image2);
    formData.append('rcid', String(rcid));
    formData.append('copyfromcoin', String(copyfrom));
    return this._http.post<any>(this._urlUploadCoinImg, formData );

  }

  getCoinScans(pageNum:string,limit:string,qparams:any){
    this.checkConfigFile();
    return this._http.get<any>(this._urlGetCoinScans, {
      params: new HttpParams().set('skip', pageNum).set('limit', limit).set('qparams',JSON.stringify(qparams))
    }); 
  }

  getLastCoinScanId(){
    this.checkConfigFile();
    return this._http.get<any>(this._urlGetLastCoinScanID,{});
  }

  getCoinScansValue(pageNum:string,limit:string,qparams:any){
    this.checkConfigFile();
    return this._http.get<any>(this._urlGetCoinScansValue, {
      params: new HttpParams().set('skip', pageNum).set('limit', limit).set('qparams',JSON.stringify(qparams))
    }); 
  }

  deleteScans(mongoId:string){
    this.checkConfigFile();
    return this._http.get<any>(this._urlDeleteCoinScan, {
      params: new HttpParams().set('id', mongoId),
    }); 
  }

  getCoinScan(customId:string){    
    this.checkConfigFile();
    return this._http.get<any>(this._urlGetCoinScan, {
      params: new HttpParams().set('customid', customId),
    }); 
  }

  getCoinScanImage(customId:string,side:string,size:number=-1){  
    this.checkConfigFile();
    return this._http.get(this._urlGetCoinScanImage, {
      params: new HttpParams().set('customid', customId).set('side',side).set('resize', size),
    responseType: 'blob'}); 
  }
  

  getNextCoinScanToVerify(limitcategory:string){
    this.checkConfigFile();
    console.log("limitcategory:"+limitcategory);
    return this._http.get<any>(this._urlNextCoinScanToV, {
      params: new HttpParams().set('limitcategory', limitcategory)
    }); 
  }

  getCategoryList(){
    this.checkConfigFile();
    return this._http.get<any>(this._urlGetCategories,{}); 
  }

  // mapToString(map){
  //   return JSON.stringify(
  //     Array.from(
  //       map.entries()
  //     )
  //     .reduce((o, [key, value]) => { 
  //       o[key] = value; 
    
  //       return o; 
  //     }, {})
  //   );
  // }

  mapToString(map: Map<any, any>): string {
    return JSON.stringify(
        Array.from(map.entries())
        .reduce((o: Record<string, any>, [key, value]: [string, any]) => { 
            o[key] = value; 
            return o; 
        }, {})
    );
}

  updateCoinScan(customid:string,date:string,categoryCode:string,diameter:string,obverseAngle:number,
    reverseAngle:number,invertScans:boolean,coinQuality:string,transferToTestSet:boolean,mintSymbol:string,
    coinFeatures:Map<string, string>){
    this.checkConfigFile();
    const formData = new FormData();
    formData.append('customid', customid);
    formData.append('date', date);
    formData.append('categoryCode', String(categoryCode));
    formData.append('diameter', String(diameter));
    formData.append('obverseAngle', String(obverseAngle));
    formData.append('reverseAngle', String(reverseAngle));
    formData.append('invertScans', String(invertScans));
    formData.append('coinQuality',coinQuality);
    formData.append('saveToTestSet',String(transferToTestSet));
    formData.append('mintSymbol',mintSymbol);



    formData.append('coinFeatures', this.mapToString(coinFeatures));
    return this._http.post<any>(this._urlUpdateCoinScan, formData );
  }

  updateCoinScanQuality(customid:string,q:string){
    this.checkConfigFile();
    return this._http.get<any>(this.  _urlUpdatFieldeCoinScan,{
      params: new HttpParams().set('customid', customid).set('fieldType', 'coinQuality').set('coinQuality',q)
    }); 
  }


  getCoinSansStats(){
    this.checkConfigFile();
    return this._http.get<any>(this.  _urlCoinScansStats,{}); 
  }
  //COIN FEATURES----------------------------------------------
  getCoinFeaturesDef(categoryCode:string,date:string,mintSymbol:string){
    this.checkConfigFile();
    const formData = new FormData();
    formData.append('categoryCode', String(categoryCode));
    formData.append('date', date);
    formData.append('mintSymbol',mintSymbol);
    return this._http.post<any>(this._urlGetCoinFeaturesDef, formData );
  }
  
  getCoinFeaturesQueryList(){
    this.checkConfigFile();
    const formData = new FormData();
    
    return this._http.post<any>(this._urlGetCoinFeaturesQueryList, formData );
  }
  
  
  //TEST--------------------------------------------------------
  getCoinTests(){
    this.checkConfigFile();
    return this._http.get<any>(this.  _urlGetAllCoinTests,{}); 
  }

  getCoinTest(tid:string){
    this.checkConfigFile();
    return this._http.get<any>(this.  _urlGetCoinTest,{
      params: new HttpParams().set('tid', tid),
    }); 
  }

  deteleCoinTest(testId:string){
    this.checkConfigFile();
    return this._http.get<any>(this._urlDeleteCoinTest, {
      params: new HttpParams().set('tid', testId),
    }); 
  }
  
  //TASKS---------------------------------------------------------
  getScanTasks(pageNum:string,limit:string){
    this.checkConfigFile();
    return this._http.get<any>(this._urlGetScanTasks,{params: new HttpParams().set('skip', pageNum).set('limit', limit)}); 
  }

  deleteScanTasks(customId:string){
    this.checkConfigFile();
    return this._http.get<any>(this._urlDeleteScanTask, {
      params: new HttpParams().set('customid', customId),
    }); 
  }

  
  insertScanTask(image1: File, image2: File,flipHorizontal:boolean,flipVertical:boolean,flipAuto:boolean){
    this.checkConfigFile();
    const formData = new FormData();
    formData.append('image1', image1);
    formData.append('image2', image2);
    formData.append('userid', this.userId);
    if (flipAuto){
      formData.append('flipMode', "auto");
      formData.append('flipVertical', String(false));
      formData.append('flipHorizontal', String(false));
    }
    else{
      formData.append('flipMode', "manual");
      formData.append('flipHorizontal', String(flipHorizontal));
      formData.append('flipVertical', String(flipVertical));
    }
    return this._http.post<any>(this._urlInsertScanTask, formData );
  }

  getCoinTask(taskId:string){
    this.checkConfigFile();
    return this._http.get<any>(this._urlGetScanTask, {
      params: new HttpParams().set('taskId', taskId),
    }); 
  }

  getScanTaskStatus(taskId:string){
    this.checkConfigFile();
    return this._http.get<any>(this._urlGetScanTaskStat, {
      params: new HttpParams().set('taskId', taskId),
    }); 
  }

  resetScanTaskStatus(taskId:string){
    this.checkConfigFile();
    return this._http.get<any>(this._urlResetScanTaskStat, {
      params: new HttpParams().set('taskId', taskId),
    }); 
  }
  //SORTER-----------------------------------------------------------------
  sorterProcess(image1: File, image2: File) {
    this.checkConfigFile();
    const formData = new FormData();
    formData.append('image1', image1);
    formData.append('image2', image2);

    return this._http.post<any>(this._urlSorterProcess, formData );
  }

  sorterProcess2(image1: File, image2: File) {
    this.checkConfigFile();
    const formData = new FormData();
    formData.append('images', image1, 'file1');
    formData.append('images', image2, 'file2');
    formData.append('save_task','True');
  
    return this._http.post(this._urlSorterProcess2, formData);
  }


  
  
  getSorterGetList(){
    this.checkConfigFile();
    return this._http.get<any>(this.  _urlSorterGetList,{});
  }

  getSorterTaskData(taskId:string){
    this.checkConfigFile();
    return this._http.get<any>(this._urlSorterTaskData, {
      params: new HttpParams().set('taskId', taskId),
    }); 
  }

  deleteSorterTaskData(taskId:string){
    this.checkConfigFile();
    return this._http.get<any>(this._urlDeleteSorterTask, {
      params: new HttpParams().set('taskId', taskId),
    }); 
  }

  getSorterItenData(customid:string){
    this.checkConfigFile();
    return this._http.get<any>(this._urlSorterItem, {
      params: new HttpParams().set('customid', customid),
    }); 
  }
  //SORTERRULES------------------------------------------------------------
  getSorterRules(){
    this.checkConfigFile();
    return this._http.get<any>(this.  _urlSorterRulesReadAll,{});
  }

  updateSorterRules(ruleList:any){
    this.checkConfigFile();
    const formData = new FormData();
    formData.append('ruleList', JSON.stringify(ruleList));
    return this._http.post<any>(this._urlSorterRulesUpdateAll, formData );
  }

  //KEYCLOAK------------------------------------------------------------------------------
  public async getRCUserProfile(){
    this.checkConfigFile();
    var KC_ADMIN_ROLE='robocoin_admin';
    
    if (this.rcUserProfile != null){
        return this.rcUserProfile;
    }
    else {
        var isLogged = await this.keycloak.isLoggedIn(); // Added await here
        if (isLogged) {
            console.log('>>>>getRCUserProfile - logged');
            var urc = new RCUserProfile();
            console.log(">>>>getRCUserProfile - loadUserProfile");
            
            try {
                var pr = await this.keycloak.loadUserProfile();
                // urc.userName = pr.username;
                if (pr.username !== undefined) {
                  urc.userName = pr.username;
                } else {
                    urc.userName = 'default';  // Replace with an actual default value or handle this case as appropriate
                }
                urc.isLoggedIn = true;
                console.log(">>>>getRCUserProfile - loadUserProfile - done");
                
                //var rolesList = this.keycloak.getKeycloakInstance().realmAccess.roles;
                var rolesList = this.keycloak.getKeycloakInstance()?.realmAccess?.roles;
                if (rolesList === undefined) {
                  console.log('>>>>getRCUserProfile - rolesList is undefined');
                }
                else{
                    for (let r of rolesList) {
                      if (r == KC_ADMIN_ROLE)
                          urc.isAdmin = true;
                  }
                }
                console.log(urc);
                return urc;
            } catch (error) {
                console.error('Error loading user profile', error);
                // Handle error or return something meaningful
                return null;
            }
        }
        else {
            return new RCUserProfile();
        }
    }
}
  // public async getRCUserProfile(){
  //   console.log('>>>>getRCUserProfile')
  //   var KC_ADMIN_ROLE='robocoin_admin';
  //   if (this.rcUserProfile!=null){
  //     return this.rcUserProfile;
  //   }
  //   else{
  //     var isLogged =  this.keycloak.isLoggedIn();
  //     if (isLogged){
  //       console.log('>>>>getRCUserProfile - logged')
  //       var urc=new RCUserProfile();
  //       console.log(">>>>getRCUserProfile - loadUserProfile");
  //       var pr =  await this.keycloak.loadUserProfile();
  //       urc.userName=pr.username;
  //       urc.isLoggedIn=true;
  //       console.log(">>>>getRCUserProfile - loadUserProfile - done");
  //       var rolesList=this.keycloak.getKeycloakInstance().realmAccess.roles
  //       for (let r of rolesList) {
  //         if (r==KC_ADMIN_ROLE)
  //           urc.isAdmin=true;
  //       }
  //       console.log(urc);
  //       return urc;
  //     }
  //     else{
  //       return new RCUserProfile();
  //     }
  //   }
  // }

  //TOOLS------------------------------
  public copyExternalFiles(){
    this.checkConfigFile();
    return this._http.get<any>(this._urlCopyExternalCSS,{});
  }

  //FACEBOOK/INSTAGRAM---------------------------
  public facebookPostCoin(customId:string){    
    this.checkConfigFile();
    return this._http.get<any>(this._urlFacebookPostCoin, {
      params: new HttpParams().set('customid', customId),
    }); 
  }
  
  public exportCoinScanImage(customId:string){    
    this.checkConfigFile();
    return this._http.get(this._urlExportCoinScanIMG, {
      params: new HttpParams().set('customid', customId),
    responseType: 'blob'}); 
  }

}
