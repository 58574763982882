<h1> coin scand page {{coinsScansPage}}</h1> 
<table>
    <tr>
        <td>
            <button  class="btn btn-primary" (click)="requestData()">reload</button>
        </td>
        
    </tr>
</table>


<mat-progress-bar mode="indeterminate" *ngIf="isWait"></mat-progress-bar>
<table class="table stat-table">
    <tr>
        <td *ngIf="coinsScansPage!==0">
            <a href="javascript:void(0);" (click)="loadPage(0)">First</a>
        </td>
        <td *ngFor="let page of pages">
            
            <a *ngIf="page!==coinsScansPage" href="javascript:void(0);" (click)="loadPage(page)">{{page}}</a>
            <a *ngIf="page===coinsScansPage" >[{{page}}]</a>
            
        </td>
    </tr>
</table>

<table class="table stat-table">
    <tr>
      <th scope="col">#</th>
      <th scope="col">id</th>
      <th scope="col">title|category|quality</th> 
      <th scope="col">obverse</th>  
      <th scope="col">rbverse</th>  
      <th scope="col">action</th>  
    </tr>
    <tr *ngFor="let coin of coinScans;index as i">
        <td>{{ coinsScansPage*coinsPerPage + i + 1 }}</td>
        <td><a [routerLink]="['/coinscanview', coin['customid']]">{{coin['customid']}}</a></td>
        <td>
         <table>  
            <tr><td>{{coin['date']}} | {{coin['categoryCode']}}</td></tr>
            <tr><td>Mint:[{{coin['mintSymbol']}}] | Quality:[{{coin['coinQuality']}}]</td></tr>
            <tr><td>source:{{coin['source']}}</td></tr>
            <tr><td>{{coin['timestamp']}}</td></tr>
            <tr><td>{{coin['rulesValueIndicator']['level']}}</td></tr>
            <tr><td>{{coin['rulesValueIndicator']['description']}}</td></tr>
          </table>
        </td>

        
        <td>
            <img *ngIf="coin['imageObverse']===''" width="30px" [src]="'assets/img/nofoto_small.png'" />
            <img *ngIf="coin['imageObverse']!==''" [src]="'data:image/jpg;base64,'+coin['imageObverse'] | safeHtml"  width="400px"/>
        </td>
        <td>
            <img *ngIf="coin['imageReverse']===''" width="30px" [src]="'assets/img/nofoto_small.png'" />
            <img *ngIf="coin['imageReverse']!==''" [src]="'data:image/jpg;base64,'+coin['imageReverse'] | safeHtml"  width="400px"/>
        </td>
        <td>

            <a *ngIf="coin['verified']===true" style="background-color: green;" >VERIFIED</a><br>
            <a *ngIf="coin['testset']===true" style="background-color: lightblue;" >TESTSET</a>
        </td>
    </tr>
</table>
<mat-progress-bar mode="indeterminate" *ngIf="isWait"></mat-progress-bar>
<table class="table stat-table">
    <tr>
        <td *ngIf="coinsScansPage!==0">
            <a href="javascript:void(0);" (click)="loadPage(0)">First</a>
        </td>
        <td *ngFor="let page of pages">
            
            <a *ngIf="page!==coinsScansPage" href="javascript:void(0);" (click)="loadPage(page)">{{page}}</a>
            <a *ngIf="page===coinsScansPage" >[{{page}}]</a>
            
        </td>
    </tr>
</table>
