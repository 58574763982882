<h1 class="gap">Insert New Coin Scan Tasks:</h1>
<table>
  <tr>
    <td >
      <img  src="assets/img/flip_auto.png"  width="30px"/>
    </td>
    <td>
      <mat-checkbox  name="flipAutomatic" [(ngModel)]="flipAutomatic">Flip Auto Detetct (QR Code)</mat-checkbox>
    </td>

  </tr>
  <tr *ngIf="!flipAutomatic">
    <td >
      <img  src="assets/img/flip_h.png"  width="30px"/>
    </td>
    <td>
      <mat-checkbox  name="flipHorizontal" [(ngModel)]="flipHorizontal">Flip Horizontal</mat-checkbox>
    </td>

  </tr>
  <tr *ngIf="!flipAutomatic">
    <td>
      <img  src="assets/img/flip_v.png"  width="30px"/>
    </td>
    <td>
      <mat-checkbox  name="flipVertical" [(ngModel)]="flipVertical">Flip Vertical</mat-checkbox>
    </td>

  </tr>
</table>

<table>  
  <tr>
    <td width="300px">
      <span style="color:red;" *ngIf="message1">{{message1}}</span>
      <input #file1 type="file" hidden="true"  accept="image/*" capture="environment" (change)="preview(file1.files,'1')" />
      <button mat-flat-button color="primary" (click)="file1.click()">Scan COIN Rev</button>
      
    </td>
    <td width="300px">
      <span style="color:red;" *ngIf="message2">{{message2}}</span>
      <input #file2 type="file"  hidden="true"  accept="image/*" capture="environment" (change)="preview(file2.files,'2')" />
      <button mat-flat-button color="primary" (click)="file2.click()">Scan COIN Obv</button>

    </td>
  </tr>
  <tr>
    <td>
      <img [src]="imgURL1" class="gap imggap"  height="150" *ngIf="imgURL1">
    </td>
    <td>
      <img [src]="imgURL2" class="gap imggap"  height="150" *ngIf="imgURL2">
    </td>
  </tr>
  <tr>
    <td>
      <button [disabled]="imgURL1==null||imgURL2==null" class="btn btn-primary gap" (click)="onSubmit()">Submit</button>
    </td>
  </tr>
  <tr>
    {{messageSubmit}}
  </tr>
</table>
<table *ngIf="newTaskId!=''">
  <tr  >
    <td>
      <button class="btn btn-primary gap" (click)="getTaskStatus()">getStatus</button>
    </td>
  </tr>
</table>
<table *ngIf="newTaskStatusData!=null">
  <tr>
    <td>{{newTaskStatusData['status']}}</td>
  </tr> 
  <tr *ngFor="let scancoin of newTaskStatusData['coinscans'];index as i">

    <td class='cointaskinfo'> <a [routerLink]="['/coinscanview', scancoin]">{{i+1}}-{{scancoin}}</a> </td>

  </tr>


</table>
<mat-progress-bar mode="indeterminate" *ngIf="isWait"></mat-progress-bar>
