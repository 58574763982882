<div *ngIf="taskData!=null">
    <table class="table table-striped">
        <tr>
            <td>TID</td>
            <td>{{taskData['customid']}}</td>
        </tr>
        <tr>
            <td>timestamp</td>
            <td>{{taskData['timestamp']}}</td>
        </tr>
        <tr>
            <td>user</td>
            <td>{{taskData['user']}}</td>
        </tr>
        <tr>
            <td>status</td>
            <td>{{taskData['status']}}</td>
        </tr>
        <tr>
            <td>IP</td>
            <td>{{taskData['ip']}}</td>
        </tr>
        <tr>
            <td>flipmode</td>
            <td>{{taskData['flipMode']}}</td>
        </tr>
        <tr>
            <td>flipHorizontal</td>
            <td>{{taskData['flipHorizontal']}}</td>
        </tr>
        <tr>
            <td>flipVertical</td>
            <td>{{taskData['flipVertical']}}</td>
        </tr>
        <tr>
            <td>scanGroup</td>
            <td>{{taskData['scanGroup']}}</td>
        </tr>
        <tr>
            <td>side1</td>
            <td>
                <img *ngIf="taskData['imageSide1']===''" width="30px" [src]="'assets/img/nofoto_small.png'" />
                <a *ngIf="taskData['imageSide1']!==''"  class="download_btn" href="javascript:void(0);" (click)="saveImage(taskData['imageSide1'])">
                    <img *ngIf="taskData['imageSide1']!==''" [src]="'data:image/jpg;base64,'+taskData['imageSide1'] | safeHtml"  width="150px"/>
                </a>
            </td>
        </tr>
        <tr>
            <td>side2</td>
            <td>
                <img *ngIf="taskData['imageSide2']===''" width="30px" [src]="'assets/img/nofoto_small.png'" />
                <a *ngIf="taskData['imageSide2']!==''"  class="download_btn" href="javascript:void(0);" (click)="saveImage(taskData['imageSide2'])">
                    <img *ngIf="taskData['imageSide2']!==''" [src]="'data:image/jpg;base64,'+taskData['imageSide2'] | safeHtml"  width="150px"/>
                </a>
            </td>
        </tr>
        <tr *ngIf="taskData['debugImage']!=null">
            <td>debugImg</td>
            <td>
                <a *ngIf="taskData['debugImage']!==''"  class="download_btn" href="javascript:void(0);" (click)="saveImage(taskData['debugImage'])">

                    <img *ngIf="taskData['debugImage']!==''" [src]="'data:image/jpg;base64,'+taskData['debugImage'] | safeHtml"  width="150px"/>
                </a>
            </td>
        </tr>
    </table>
    <table class="table table-striped">
        <tr *ngFor="let scancoin of taskData['coinscans'];index as t" class='cointaskinfo'>
            <td class='cointaskinfo'>{{t+1}}</td>
            <td class='cointaskinfo'>-</td>
            <td class='cointaskinfo'> <a [routerLink]="['/coinscanview', scancoin]">{{scancoin}}</a> </td>
    
          </tr>

    </table>

</div>