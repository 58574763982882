import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { CoinsearchService } from '../coinsearch.service';

@Component({
    selector: 'app-cointests',
    templateUrl: './cointests.component.html',
    styleUrls: ['./cointests.component.css'],
    standalone: false
})
export class CointestsComponent implements OnInit {
  public testData: any  = null;
  public isWait              : boolean=false;
  public showMintTest        : boolean=false;
  public showDateTest        : boolean=false;
  public showClassTest       : boolean=false;
  public showCutTest         : boolean=false;
  public showDiameterTest    : boolean=false;
  public showSimilarityTest  : boolean=false;
  public showCoinQualityTest : boolean=false;


  constructor(private _coinSearchService: CoinsearchService, private _Activatedroute: ActivatedRoute) { 
    this.requestData()
  }

  renderData(data:any){
    console.log(data)
    this.isWait=false;
    this.testData = data;
  }

  deleteTest(tid:any){
    this._coinSearchService.deteleCoinTest(tid)
    .subscribe(
      data => this.requestData(), 
      error => console.error('Error!', error)
    );
  }
  requestData(){
    this.isWait=true;
    this._coinSearchService.getCoinTests()
    .subscribe(
      data => this.renderData(data), 
      error => console.error('Error!', error)
    );
  }
  getObjectValue(obj:any, key:string){
    return obj.value[key];
  }

  ngOnInit(): void {
    
  }

}
