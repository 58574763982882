import {Component, OnInit} from '@angular/core';
import {ImportAllMaterialModule} from '../material-module';
import { DomSanitizer, SafeUrl} from '@angular/platform-browser';
import {CoinsearchService} from '../coinsearch.service';
import {ActivatedRoute} from '@angular/router';


@Component({
  selector: 'app-coinmatch',
  templateUrl: './coinmatch.component.html',
  styleUrls: ['./coinmatch.component.css']
})
export class CoinmatchComponent implements OnInit {
  public imgURL1: any= null;
  public message1: string='';

  public imgURL2: any= null;
  public message2: string='';


  public resultTitle: string='';

  public matchingData: any = null;
  public isWait = false;
  public diameter: Number=0;
  public isDebug = false;
  public noImages = false;
  public isdirty = true;
  public bestMatch: any = null;
  public limitRCIDList = '';
  public matchValue =''
  public templateDebugData: any = null;
  
  //recognize data
  public recognizedData = null;
  public matchedCoins = null;
  public coinScanToMatch:string = '';
  public img1Blob:any = null;
  public img2Blob:any = null;



  preview(files:any, tag:string) {
    if (files.length === 0) {
      return;
    }

    const mimeType = files[0].type;
    if (mimeType.match(/image\/*/) == null) {
      this.message1 = 'Only PNG images are supported.';
      return;
    }

    const reader = new FileReader();

    if (tag === '1') {
      this.img1Blob=files[0];
      reader.readAsDataURL(files[0]);
      reader.onload = (_event) => {
        this.imgURL1 = reader.result;
      };
    }
    if (tag === '2') {

      this.img2Blob=files[0];
      reader.readAsDataURL(files[0]);
      reader.onload = (_event) => {
        this.imgURL2 = reader.result;

      };
    }
  }

  constructor(private _coinSearchService: CoinsearchService, private _Activatedroute: ActivatedRoute,private sanitizer: DomSanitizer) {
  }

  ngOnInit(): void {
    const csid = this._Activatedroute.snapshot.paramMap.get('csid');
    this.coinScanToMatch = csid !== null ? csid : '';

    if (this.coinScanToMatch !== '' && this.coinScanToMatch != null){
      this._coinSearchService.getCoinScanImage(this.coinScanToMatch,'O').subscribe(
        result => {
          this.img1Blob=result;
          let objectURL = URL.createObjectURL(result);
          this.imgURL1=this.sanitizer.bypassSecurityTrustUrl(objectURL);      
        });
      this._coinSearchService.getCoinScanImage(this.coinScanToMatch,'R').subscribe(
        result => {
          this.img2Blob=result;
          let objectURL = URL.createObjectURL(result);
          this.imgURL2=this.sanitizer.bypassSecurityTrustUrl(objectURL);      
        });

    }
  }




  rednerMatch(data: any) {
    this.isWait = false;
    //console.log('rednerMatch');
    console.log(data);
    //console.log(data.bestMatch);
    //this.matchingData = data.raportSides;
    this.bestMatch = data;
    this.templateDebugData = data.templateDebugData;
    let grValueMin = -1;
    let grValueMax =  -1;   
    //console.log(this.bestMatch['coin']['gradeData']);
    // this.bestMatch['coin']['gradeData'].forEach(element => {
    //   if (element[1]!='-'){
    //     let gv=+element[1];
    //     if (grValueMin<0) {grValueMin=gv}
    //     if (grValueMax<0) {grValueMax=gv}
    //     if (grValueMin>gv) {grValueMin=gv}
    //     if (grValueMax<gv) {grValueMax=gv}
    //   }
    // });
    this.bestMatch['coin']['gradeData'].forEach((element: [string, string]) => {
      const [, value] = element; // Destructuring the element array to get the value directly
    
      if (value !== '-') {
        const gv = +value; // Convert string to number using unary plus operator
        if (grValueMin < 0 || grValueMin > gv) {
          grValueMin = gv;
        }
        if (grValueMax < 0 || grValueMax < gv) {
          grValueMax = gv;
        }
      }
    });
    this.matchValue = "$ "+grValueMin+" - "+grValueMax;

  }

  rednerMatch2(data: any){
    this.isWait = false;
    console.log(data);
    this.bestMatch = data['cataloguecoins'];
    this.recognizedData=data;
  }

  coinMatch2(): void {
    this.isWait = true;
    this, this._coinSearchService.matchCoins2(this.img1Blob, this.img2Blob, this.diameter, this.isDebug,this.isdirty,this.noImages)
      .subscribe(
        data => this.rednerMatch2(data),
        error => console.error('Error!', error)
      );
  }




}
