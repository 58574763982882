<h1 class="gap"> ADD SORT COIN:</h1>
<table>
  <tr>
    <td class="left-cell" >
      <!-- SORTER MAIN -->
      <table>
        <tr>
            <td class="left-btn">
              <span style="color:red;" *ngIf="message1">{{message1}}</span>
              <input #file1 type="file" hidden="true"  accept="image/*" capture="environment" (change)="preview(file1.files,'1')" />
              <button mat-flat-button color="primary" (click)="file1.click()">Scan COIN</button>
            </td> 
            <td class="left-img">
              <img [src]="imgURL1" class="gap imggap"  height="150" *ngIf="imgURL1">
            </td>
        </tr>
        <tr>
            <td class="left-btn">
              <span style="color:red;" *ngIf="message2">{{message2}}</span>
              <input #file2 type="file"  hidden="true"  accept="image/*" capture="environment" (change)="preview(file2.files,'2')" />
              <button mat-flat-button color="primary" (click)="file2.click()">Scan COIN</button>
            </td>
            <td class="left-img">
              <img [src]="imgURL2" class="gap imggap"  height="150" *ngIf="imgURL2">
            </td> 
        </tr>
        <tr>
          <td>
            <button [disabled]="imgURL1==null||imgURL2==null" class="btn btn-primary gap" (click)="onSubmit()">Submit</button>
          </td>
        </tr>
      </table>
    </td>
    <td class="right-cell">
      <!-- EXTERNAL IMAGES-->
      <table *ngIf="externalImagesist.length > 0">
        <tr *ngFor="let image of externalImagesist">
          <td>
            <img [src]="image" alt="Image" width="100">
          </td>
          <td class="right-btn">
            <button (click)="loadExternal(image,'1')">ADD1</button>
            <button (click)="loadExternal(image,'2')">ADD2</button>
          </td>
        </tr>
      </table>
    </td>
  </tr>
</table>
<mat-progress-bar mode="indeterminate" *ngIf="isWait" ></mat-progress-bar>
<div class="dataview">
  <pre>{{resultData | json}}</pre>
</div>
