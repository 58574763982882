import { Component, OnInit } from '@angular/core';
import { CoinsearchService } from '../coinsearch.service';

@Component({
    selector: 'coindbstatus',
    templateUrl: './coindbstatus.component.html',
    styleUrls: ['./coindbstatus.component.css'],
    standalone: false
})
export class CoindbstatusComponent implements OnInit {

  jcoinDBStatus: any=null;
  public isWait = true;
  constructor(private _coinSearchService: CoinsearchService) {
    console.log('dbcoin status requested');
    if (this._coinSearchService.getLastCoinDBStatus() === null) {
      this._coinSearchService.getCoinDBStatus()
        .subscribe(
          data => this.renderStatus(data),
          error => console.error('Error!', error)
        );
    } else {
      this.renderStatus(this._coinSearchService.getLastCoinDBStatus());
    }
  }

  renderStatus(data:any) {
    console.log(data);
    this.jcoinDBStatus = data;
    this._coinSearchService.setLastCoinDBStatus(data);
    this.isWait = false;
  }

  ngOnInit(): void {
  }

}
