import { Component, OnInit  } from '@angular/core';
import { Router, NavigationEnd, ActivatedRoute } from '@angular/router';
import { filter } from 'rxjs/operators';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.css'],
    standalone: false
})
export class AppComponent implements OnInit{
  title = 'robocoinportal';
  showMiniToolbar: boolean = true;

  constructor(private router: Router, private activatedRoute: ActivatedRoute) {
    // Listen to changes in the route
    this.router.events.pipe(
      filter(event => event instanceof NavigationEnd)
    ).subscribe(() => {
      this.updateToolbarVisibility();
    });
  }

  updateToolbarVisibility() {
    // Get the active route after navigation ends
    const activeRoute = this.activatedRoute.firstChild;
    // Check if the route matches '/lastscan'
    this.showMiniToolbar = !(activeRoute && activeRoute.snapshot.url.map(segment => segment.path).includes('lastscan'));
  }


  public async ngOnInit() {
  }

}
