<mat-progress-bar mode="indeterminate" *ngIf="isWait" ></mat-progress-bar>

<div *ngIf="!isWait">
    <div class="red">{{errorMsg}}</div>
    <table class="table stat-table">
        <tr>
          <th scope="col">name</th>
          <th scope="col">value</th>  

        </tr>
        <tr>
            <td>id</td>
            <td>{{ templateData['id'] }}</td>
        </tr>
        <tr>
            <td>category</td>
            <td>{{ templateData['coinCategoryCode'] }}</td>
        </tr>
    </table>


    <table class="table stat-table">
        <tr *ngFor="let img of templateProcData;index as i">
            <td> 
            <img  [src]="'data:image/jpg;base64,'+img | safeHtml"  width="300px"/>         
            </td>    
        
        </tr>
    </table>






</div>