import { Component, OnInit } from '@angular/core';
import { CoinsearchService } from '../coinsearch.service';
import { Router, ActivatedRoute, Params } from '@angular/router';

@Component({
  selector: 'app-coinscansvalue',
  templateUrl: './coinscansvalue.component.html',
  styleUrls: ['./coinscansvalue.component.css']
})
export class CoinscansvalueComponent implements OnInit {
  public isWait             : boolean=false;
  public coinScansCount     : number = 0;
  public coinsScansPage     : number = 0;
  public coinsPerPage       : number = 5;
  public coinScans          : any=null;
  public pages              : number[]=[];
  constructor(private _coinSearchService: CoinsearchService,private route: Router) {
    
    console.log('coin scans requested');
    this.requestData()
  }


  requestData(){
    this.isWait=true;
    var qparams:any = {};
    qparams.categoryCode = '';
    qparams.coinDate = '';
    qparams.coinMint = '';
    
    this._coinSearchService.getCoinScansValue((this.coinsScansPage*this.coinsPerPage).toString(),this.coinsPerPage.toString(),qparams)
    .subscribe(
      data => this.renderStatus(data), 
      error => console.error('Error!', error)
    );
  }
 
  calculatePages(data:any){
    this.pages=[];
    let start:number = this.coinsScansPage - 5;
    if (start<0){
      start=0;
    }
    for (let i = start; i < start+10; i++) {
      this.pages.push(i)
    }
    console.log(this.pages)
  }
  
  renderStatus(data:any){
    console.log(data)
    this.isWait=false;
    this.calculatePages(data)
    this.coinScansCount = data['scanscount'];
    this.coinScans      = data['coinscans']
  }

  loadPage(pageNum:number){
    this.coinsScansPage=pageNum;
    this.requestData();
  }
  ngOnInit(): void {
  }

}
