import { Component, OnInit } from '@angular/core';
import {ImportAllMaterialModule} from '../material-module';
import {CoinsearchService} from '../coinsearch.service';
import { SafeHtmlPipe } from '../safe-html.pipe';

@Component({
  selector: 'app-mobilematch',
  templateUrl: './mobilematch.component.html',
  styleUrls: ['./mobilematch.component.css']
})
export class MobilematchComponent implements OnInit {
  public imagePath1='';
  public imgURL1: any= null;
  public message1: string='';

  public imagePath2=  '';
  public imgURL2: any= null;
  public message2: string='';
  public isWait = false;
  public bestMatch: any = null;
  public matchValue =''

  public diameter = 0;

  constructor(private _coinSearchService: CoinsearchService) { }

  preview(files:any, tag:string) {
    if (files.length === 0) {
      return;
    }

    const mimeType = files[0].type;
    if (mimeType.match(/image\/*/) == null) {
      this.message1 = 'Only PNG images are supported.';
      return;
    }

    const reader = new FileReader();

    if (tag === '1') {
      //console.log(files);
      this.imagePath1 = files;
      
      reader.onload = (_event) => {
        this.imgURL1 = reader.result;
      };
      reader.readAsDataURL(files[0]);
    }
    if (tag === '2') {
      //console.log(files);
      this.imagePath2 = files;
     
      reader.onload = (_event) => {
        this.imgURL2 = reader.result;

      };
      reader.readAsDataURL(files[0]);
    }
  }

  rednerMatch(data:any) {
    this.isWait = false;
    //console.log('rednerMatch');
    console.log(data);
    //console.log(data.bestMatch);
    //this.matchingData = data.raportSides;
    this.bestMatch = data;
    let grValueMin = -1;
    let grValueMax =  -1;   
    //console.log(this.bestMatch['coin']['gradeData']);
    this.bestMatch['coin']['gradeData'].forEach((element:any) => {
      if (element[1]!='-'){
        let gv=+element[1];
        if (grValueMin<0) {grValueMin=gv}
        if (grValueMax<0) {grValueMax=gv}
        if (grValueMin>gv) {grValueMin=gv}
        if (grValueMax<gv) {grValueMax=gv}
      }
    });
    this.matchValue = "$ "+grValueMin+" - "+grValueMax;

  }

  onSubmit(): void {
    this.isWait = true;

    this, this._coinSearchService.matchCoins(this.imgURL1, this.imgURL2, 0, false,true, '')
      .subscribe(
        data => this.rednerMatch(data),
        error => console.error('Error!', error)
      );
  }

  ngOnInit(): void {
  }

}
