<mat-progress-bar mode="indeterminate" *ngIf="isWait" ></mat-progress-bar>
<table class="table stat-table"> 
    <tr>

        <td>

       <mat-button-toggle-group [(ngModel)]="viewType" (change)="viewChanged()" >
        <mat-button-toggle [value]="'EMPTY'"> show Empty Only </mat-button-toggle>
        <mat-button-toggle [value]="'LABELED'"> show Labeled Only </mat-button-toggle>
        <mat-button-toggle [value]="'ERROR'"> show Wrong Reco Only </mat-button-toggle>
        <mat-button-toggle [value]="'ALL'"> show All </mat-button-toggle>
    </mat-button-toggle-group>
    </td>
    <td>
        <mat-checkbox  name="showQClassA" (change)="viewChanged()"  [(ngModel)]="showQClassA">show Class A</mat-checkbox>
        <mat-checkbox  name="showQClassB" (change)="viewChanged()" [(ngModel)]="showQClassB">show Class B</mat-checkbox>
        <mat-checkbox  name="showQClassC" (change)="viewChanged()" [(ngModel)]="showQClassC">show Class C</mat-checkbox>
        <mat-checkbox  name="showQClassD" (change)="viewChanged()" [(ngModel)]="showQClassD">show Class D</mat-checkbox>
    </td>
</tr>
</table>




<div *ngIf="testData!=null">
    <table>
        <tr>
            <td *ngFor="let pg of getPaginatorNums();index as i">
                <button [ngClass]="{'currPage':pg===currentPage}" class="button-wrapper"  (click)="changeCurrentPage(pg)">{{pg+1}}</button>
                
            </td>
        </tr>
    </table>
    <table class="table stat-table">
        <tr *ngFor="let test of testData['results'];index as i">
            <table>
                <tr>
                    
                    <td>{{test['codeName']}} </td>
                    <td>{{test['category']}}</td>
                    <td>matched:{{test['matched']}}</td>
                    <td>notmatched:{{test['notmatched']}}</td>
                    <td>prc:{{test['prc']}}%</td>
                    <td>items in view [{{totolViewImages}}]</td>
                </tr>
            </table>
            <table>
            <!--<tr *ngFor="let coin of test['coins'];index as ci;trackBy:coinTrackBy">--->
                <tr *ngFor="let coin of displayPageItems;index as ci;trackBy:coinTrackBy">
                    <td>{{currentPage*pageSize+ci+1}}</td>
                    <td><a [routerLink]="['/coinscanview', coin['customid']]" > {{coin['customid']}} </a></td>
                    <td>{{coin['reco_q']}}</td>
                    <td [ngClass]="{'recoNONE':coin['coinQuality']==='' ||coin['coinQuality']===undefined ,'recoGOOD': coin['reco_q']===coin['coinQuality'],'recoBAD': coin['coinQuality']!==undefined && coin['coinQuality']!=='' && coin['reco_q']!==coin['coinQuality']}">{{coin['coinQuality']}}</td>
                    <td>
                        <button class="button-wrapper"  (click)="setQuality(coin['customid'],'A')">A</button>
                        <button class="button-wrapper"  (click)="setQuality(coin['customid'],'B')">B</button>
                        <button class="button-wrapper"  (click)="setQuality(coin['customid'],'C')">C</button>
                        <button class="button-wrapper"  (click)="setQuality(coin['customid'],'D')">D</button>
                        <button class="button-wrapper"  (click)="setQuality(coin['customid'],'')">N</button>
                    </td>
                           
                    <td class='coinCell'>
                        <img *ngIf="coin['imgO'] === undefined || coin['imgO']===''" width="30px" [src]="'assets/img/nofoto_small.png'" />
                        <img *ngIf="coin['imgO'] !== undefined && coin['imgO']!==''"  (click)="toggleImageSize(ci)" [ngClass]="imageSizeArr[ci]" [src]="'data:image/jpg;base64,'+coin['imgO'] | safeHtml"  />
                    </td>
                    <td class='coinCell'>
                        <img *ngIf="coin['imgR'] === undefined || coin['imgR']===''" width="30px" [src]="'assets/img/nofoto_small.png'" />
                        <img *ngIf="coin['imgR'] !== undefined && coin['imgR']!==''"  (click)="toggleImageSize(ci)" [ngClass]="imageSizeArr[ci]" [src]="'data:image/jpg;base64,'+coin['imgR'] | safeHtml"  />
                    </td>
                </tr>
            </table>
        </tr>
    </table>

    <table>
        <tr>
            <td *ngFor="let pg of getPaginatorNums();index as i">
                <button [ngClass]="{'currPage':pg===currentPage}" class="button-wrapper"  (click)="changeCurrentPage(pg)">{{pg+1}}</button>
                
            </td>
        </tr>
    </table>
    
</div>

