import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { CoinsearchService } from '../coinsearch.service';
import { SafeHtmlPipe } from '../safe-html.pipe';

@Component({
  selector: 'app-templateview',
  templateUrl: './templateview.component.html',
  styleUrls: ['./templateview.component.css']
})
export class TemplateviewComponent implements OnInit {
  public templateData: any = null;
  public templateProcData:any = null;
  public errorMsg: string = '';
  public isWait = true;
  constructor(private _coinSearchService: CoinsearchService, private _Activatedroute: ActivatedRoute) { }


  renderData(data):void{
    this.templateData=data['template'];
    this.templateProcData=data['debuglog'];
    this.errorMsg=data['error']
    this.isWait= false;
  }

  ngOnInit(): void {
    this.isWait = true;
    const tid = this._Activatedroute.snapshot.paramMap.get('tid');
    this._coinSearchService.getTemplate(tid)
    .subscribe (
      data => this.renderData(data),
      error => console.error('Error!', error)
    );
  }

}
