<table class="table stat-table">
    <tr>
      <th scope="col">#</th>
      <th scope="col">id</th>
      <th scope="col">category</th>  
    </tr>
    <tr *ngFor="let temp of jcoinTemplates;index as i">
        <td>{{ i + 1 }}</td>
        <td><a [routerLink]="['/templateview', temp['id']]">{{temp['id']}} </a></td>
        <td>{{temp['coinCategoryCode']}}</td>
    </tr>
</table>
