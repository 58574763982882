<hr>
<div class="example-container">
  <div class="search-row">
    <form (ngSubmit)="onSubmit()">
      <mat-form-field appearance="fill" class="gap">
        <mat-label>coinNID</mat-label>
        <input matInput name="coinNID" [(ngModel)]="searchQuery.queryNID">
        <button mat-button mat-icon-button matSuffix class="small-icon" (click)="searchQuery.queryNID=''"
                *ngIf="searchQuery.queryNID">
          <mat-icon>close</mat-icon>
        </button>
      </mat-form-field>

      <mat-form-field appearance="fill" class="gap">
        <mat-label>Category</mat-label>
        <input matInput name="categoryID" [(ngModel)]="searchQuery.categoryID">
        <button mat-button mat-icon-button matSuffix class="small-icon" (click)="searchQuery.categoryID=''"
                *ngIf="searchQuery.categoryID">
          <mat-icon>close</mat-icon>
        </button>
      </mat-form-field>
      <mat-checkbox  name="showCollectors" [(ngModel)]="searchQuery.showCollectors">showCollectors</mat-checkbox>
      <button class="btn btn-primary gap">Submit</button>
      <span class="texarea-container">
        <!--<textarea class="coinnotes" name="coinnotes" mdInput placeholder="coinnotes" rows="5" cols="120" wrap="off"
                  [(ngModel)]="coinNotes"></textarea>-->
      </span>
    </form>

    <mat-progress-bar mode="indeterminate" *ngIf="isWait"></mat-progress-bar>
  </div>

</div>


<mat-paginator #paginator [pageSizeOptions]="[10, 20, 50]" (page)="onPaginateChange($event)"
               showFirstLastButtons></mat-paginator>
<table mat-table [dataSource]="dataSource" class="mat-elevation-z8">


  <!-- index column -->
  <ng-container matColumnDef="index" >
    <th mat-header-cell *matHeaderCellDef> title</th>
    <td mat-cell *matCellDef="let element; let i = index;" >{{i + 1}} </td>
  </ng-container>

  <!-- obverse column -->
  <ng-container matColumnDef="obverse">
    <th mat-header-cell *matHeaderCellDef> obverse</th>
    <td mat-cell *matCellDef="let coin" >
      <img *ngIf="coin.imageObverse===''" width="30px" [src]="'assets/img/nofoto_small.png'"/>
      <img *ngIf="coin.imageObverse!==''" [src]="'data:image/jpg;base64,'+coin.imageObverse | safeHtml" width="150px"/>
    </td>
  </ng-container>
  <!-- reverse column -->
  <ng-container matColumnDef="reverse">
    <th mat-header-cell *matHeaderCellDef> reverse</th>
    <td mat-cell *matCellDef="let coin" >
      <img *ngIf="coin.imageReverse===''" width="30px" [src]="'assets/img/nofoto_small.png'"/>
      <img *ngIf="coin.imageReverse!==''" [src]="'data:image/jpg;base64,'+coin.imageReverse | safeHtml" width="150px"/>
    </td>

  </ng-container>

  <!-- title column -->
  <ng-container matColumnDef="title">
    <th mat-header-cell *matHeaderCellDef> title</th>
    <td mat-cell *matCellDef="let element" [ngClass]="{'basecoin': element.sms === false && element.proof === false,'colectorscoin': element.sms === true || element.proof === true}">
      <tr>
        <td ><a [routerLink]="['/coinview', element.rcid]"> {{element.title}}</a></td>
      </tr>
      <!--
      <tr>
        <td class='link-cell' >
          <button (click)="addNote(element.localLink)">+</button>
          {{element.localLink}}</td>
      </tr>
      -->
      <tr>
        <td colspan="2" align="center">
          <tr><td *ngFor="let grd of element.priceInfo" class='gradecell'>{{grd[0]}}</td></tr>
          <tr><td *ngFor="let grd of element.priceInfo" class='gradecell'>{{grd[1]}}</td></tr>
        </td>
      </tr>
      <!--<tr><td>sms:{{ element.sms }}</td><td>proof:{{ element.proof }}</td></tr>-->

    </td>
  </ng-container>

  <!-- diameter column -->
  <ng-container matColumnDef="diameter" >
    <th mat-header-cell *matHeaderCellDef> diameter</th>
    <td mat-cell *matCellDef="let element" [ngClass]="{'basecoin': element.sms === false && element.proof === false,'colectorscoin': element.sms === true || element.proof === true}"> {{element.diameter}} </td>
  </ng-container>

  <!-- category column -->
  <ng-container matColumnDef="category">
    <th mat-header-cell *matHeaderCellDef> category</th>
    <td mat-cell *matCellDef="let element" [ngClass]="{'basecoin': element.sms === false && element.proof === false,'colectorscoin': element.sms === true || element.proof === true}"> {{element.category}} </td>
  </ng-container>


  <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
  <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
</table>






