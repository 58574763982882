import { Component, OnInit } from '@angular/core';
import { CoinsearchService,RCUserProfile } from '../coinsearch.service';
import { KeycloakService } from 'keycloak-angular';
import { CommonModule } from '@angular/common';

@Component({
    selector: 'app-profileview',
    templateUrl: './profileview.component.html',
    styleUrls: ['./profileview.component.css'],
    standalone: false
})

export class ProfileviewComponent implements OnInit {
  public rcUserProfile      : RCUserProfile=new RCUserProfile();
  public isLoggedin         : boolean=false;
  public userProfileData    : any;

  constructor(private readonly keycloak: KeycloakService,private _coinSearchService: CoinsearchService) { 
    this._coinSearchService.getRCUserProfile().then(response => {
      if (response!=null){
        this.rcUserProfile=response;
      }
    })
  }

  endSession(session_id: string) {
    this._coinSearchService.logoutUserSession(session_id,'').subscribe( (data: any) => {
      this.updateSessionData()
    });
  }

  updateSessionData(){
    this._coinSearchService.getUserProfileData().subscribe((data: any[]) => {
      this.userProfileData=data;
      console.log(this.userProfileData);
    });
  }
  
  ngOnInit(): void {
    this.keycloak.isLoggedIn().then((loggedIn) => {
      console.log('User is logged in: ' + loggedIn);
      this.isLoggedin=loggedIn;
      if (this.isLoggedin){
          this.updateSessionData();

      }
      else{
        console.log('User not logged in');
      }

    });

  }

}
