<div *ngIf="!isLoggedin">
    <div class="image-container">
        <img class='centarl_image' src="assets/img/logo2.png" alt="">
    </div>
</div>
<div *ngIf="isLoggedin && userProfileData">
    <div class="section_title" >User Sessions:</div>
    <table class="modern-table">
      <thead class="modern-table-header">
        <tr>
          <th>IP Address</th>
          <th>Last Access</th>
          <th>Session Type</th>
          <th>Start Time</th>
          <th>Action</th>
        </tr>
      </thead>
      <tbody class="modern-table-body">
        <tr *ngFor="let user of userProfileData.sessions">
          <td>{{ user.ipAddress }}</td>
          <td>{{ user.lastAccess | date: 'medium' }}</td>
          <td>{{ user.start | date: 'medium' }}</td>
         <td><a *ngFor="let cl of user.clients; let i = index">{{cl}}<ng-container *ngIf="i < user.clients.length - 1"> | </ng-container></a></td>
          <td>
              <button (click)="endSession(user.clients)">End Session</button>
          </td>
        </tr>
      </tbody>
    </table>
    <div class="section_title">Coins Statistics:</div>
    <table class="modern-table">
        <thead class="modern-table-header">
          <tr>
            <th>date</th>
            <th>1ct</th>
            <th>5ct</th>
            <th>10ct</th>
            <th>25ct</th>
            <th>total</th>
          </tr>
        </thead>
        <tbody class="modern-table-body">
            <tr>
                <td>total coins</td>
                <td *ngFor="let tot_count of userProfileData.coins_stats.total_coins.count_data">
                  {{ tot_count.count }}
                </td>
                <td>{{userProfileData.coins_stats.total_coins.total}}</td>
            </tr>
          <tr *ngFor="let st of userProfileData.coins_stats.lastdays">
            <td>{{ st.date }}</td>
            <td *ngFor="let st_count of st.count_data">
              {{ st_count.count }}
            </td>
            <td>{{st.total}}</td>
          </tr>
        </tbody>
      </table>
</div>


