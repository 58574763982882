import { BrowserModule } from '@angular/platform-browser';
import { APP_INITIALIZER,NgModule } from '@angular/core';
import { HttpClientModule } from '@angular/common/http';
import { AppComponent } from './app.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SafeHtmlPipe } from './safe-html.pipe'
import { KeycloakAngularModule, KeycloakService } from 'keycloak-angular';
import { AppRoutingModule, routingComponents} from './app-routing.module';
import { ImportAllMaterialModule } from './material-module'
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { TemplatesComponent } from './templates/templates.component';
import { TemplateviewComponent } from './templateview/templateview.component';
import { CoinscansComponent } from './coinscans/coinscans.component';
import { MobilematchComponent } from './mobilematch/mobilematch.component';
import { CoinscanviewComponent } from './coinscanview/coinscanview.component';
import { CointestsComponent } from './cointests/cointests.component';
import { ScantasksComponent } from './scantasks/scantasks.component';
import { ScantaskinsertComponent } from './scantaskinsert/scantaskinsert.component';
import { CoinsorterComponent } from './coinsorter/coinsorter.component';
import { SorterlistComponent } from './sorterlist/sorterlist.component';
import { CoinqualityComponent } from './coinquality/coinquality.component';
import { ScanTaskViewComponent } from './scan-task-view/scan-task-view.component';
import { CoinscansvalueComponent } from './coinscansvalue/coinscansvalue.component';
import { SorterrulesComponent } from './sorterrules/sorterrules.component';
import { ProfileviewComponent } from './profileview/profileview.component';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { FilterPipe } from './maintoolbar/pipes/filter.pipe';
import { NgxDaterangepickerMd } from 'ngx-daterangepicker-material';
import { NgxPaginationModule } from 'ngx-pagination';
import { LastscanComponent } from './lastscan/lastscan.component';



function initializeKeycloak(keycloak: KeycloakService) {
  return () => 
    new Promise<void>(async (resolve, reject) => {  // Added async keyword here
      try {
        //console.log('Starting to initialize Keycloak init');
        const response = await fetch('/assets/config.json');
        const config = await response.json();
        //const kkurl= `https://${config.KEYCLOAK_TCP_ADDR}:${config.KEYCLOAK_TCP_PORT}/auth`;
        //const kkurl= `https://${config.KEYCLOAK_TCP_ADDR}/auth`;
        const kkurl = config.KEYCLOAK_TCP_ADDR

        // console.log('connectinh to kk:['+kkurl+']');
        // console.log(window.location.origin);
        await keycloak.init({
          config: {
            url: kkurl,
            realm: 'robocoin',
            clientId: 'robocoin-portal'
          },
          initOptions: {
            onLoad: 'check-sso',
            silentCheckSsoRedirectUri:
              window.location.origin + '/assets/verify-sso.html'
          }
        });
        //console.log('Keycloak initialized successfully');
        resolve();
      } catch (error) {
        console.error('Keycloak initialization failed', error);
        reject(error);
      }
    });
}


@NgModule({
  declarations: [
    AppComponent,
    SafeHtmlPipe,
    routingComponents,
    TemplatesComponent,
    TemplateviewComponent,
    CoinscansComponent,
    MobilematchComponent,
    CoinscanviewComponent,
    CointestsComponent,
    ScantasksComponent,
    ScantaskinsertComponent,
    CoinsorterComponent,
    SorterlistComponent,
    CoinqualityComponent,
    ScanTaskViewComponent,
    CoinscansvalueComponent,
    SorterrulesComponent,
    ProfileviewComponent,
    LastscanComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    FormsModule,
    BrowserAnimationsModule,
    ImportAllMaterialModule,
    KeycloakAngularModule,
    MatIconModule,
    ReactiveFormsModule,
    MatButtonToggleModule,
    NgxDaterangepickerMd.forRoot(),
    NgxPaginationModule
  ],
  providers: [
    {
      provide: APP_INITIALIZER,
      useFactory: initializeKeycloak,
      multi: true,
      deps: [KeycloakService]
  }],
  bootstrap: [AppComponent]
})
export class AppModule { }
