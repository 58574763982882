<mat-progress-bar mode="indeterminate" *ngIf="isWait!=0"></mat-progress-bar>
<div class="sr-toolbar">
    <div class="sorting_rules_save">
      <button (click)="saveChanges()" class="save-btn">Save Changes</button>
    </div>
    <div>
      <button (click)="downloadJson()" class="save-btn">Export JSON</button>
    </div>
    <div class="sorting_info">Sorting Rules count [{{ getSoringRulesCount() }}]</div>
    <div *ngIf="showMessage" class="message">{{this.message}}</div>
  </div>
<table class="rcrulesmainviewtable">
    <tr>
        <td class="rcrulesmainviewtable_col1">
            
            <div class="form-wrapper">
                <h2  class="sortform-title">Add Sorting Rule</h2>
                <form class="add-item-form" (ngSubmit)="addItem()" #addItemForm="ngForm">
                    <div class="form-group">
                      <label for="categoryCode">Category Code:</label>
                      <select id="categoryCode" name="categoryCode" [(ngModel)]="newItem.categoryCode" >
                        <option value="" disabled>Select a category</option>
                        <option *ngFor="let category of categories" [value]="category">{{ category }}</option>
                      </select>
                    </div>
                    <div class="form-group">
                      <label for="enabled">Enabled:</label>
                      <label class="switchForm">
                        <input type="checkbox" id="enabledFormBox" name="enabled" [(ngModel)]="newItem.enabled" >
                        <span class="slider"></span>
                      </label>
                    </div>
                    <div class="form-group">
                      <label for="dates">Dates:</label>
                      <input type="text" id="dates" name="dates" [(ngModel)]="newDates" placeholder="e.g. 1992, 1998, 1999">
                    </div>
                    <div class="form-group">
                      <label for="mintSymbols">Mint Symbols:</label>
                      <input type="text" id="mintSymbols" name="mintSymbols" [(ngModel)]="newMintSymbols" placeholder="e.g. P, D, S">
                    </div>
                    <div class="form-group">
                      <label for="coinFeature">Coin Feature:</label>
                      <select id="coinFeature" name="coinFeature" [(ngModel)]="newItemCF" >
                        <option value="" disabled>Select a feature</option>
                        <option *ngFor="let feature of coinFeatures" [value]="createCFOption(feature)">{{ feature.id }}:{{ feature.value }}</option>
                      </select>
                    </div>
                    <div class="form-group">
                      <label for="valueIndicatorLevel">Value Level:</label>
                      <select id="valueIndicatorLevel" name="valueIndicatorLevel" [(ngModel)]="newItem.valueIndicator.level" required>
                        <option value="" disabled>Select a level</option>
                        <option value="HIGH">High</option>
                        <option value="MED">Medium</option>
                        <option value="LOW">Low</option>
                      </select>
                    </div>
            
                    <div class="form-group">
                      <label for="valueIndicatorDescription">Value Description:</label>
                      <input type="text" id="valueIndicatorDescription" name="valueIndicatorDescription" [(ngModel)]="newItem.valueIndicator.description" required>
                    
                    </div>
                    <div class="form-group">
                        <label for="novelty">Novelty:</label>
                        <label class="switchForm">
                            <input type="checkbox" id="noveltyFormBox" name="novelty" [(ngModel)]="newItem.novelty">
                            <span class="slider"></span>
                        </label>
                    </div>
                  <button type="submit" [disabled]="addItemForm.invalid">Add Item</button>
                </form>
              </div>
        </td>
        <td class="rcrulesmainviewtable_col2">
            <div class="table-wrapper">
                <table class="coin-table">
                    <thead>
                    <tr>
            
                        <th>Category Code</th>
                        <th>Enabled</th>
                        <th>Dates</th>
                        <th>Mint Symbols</th>
                        <th>Coin Feature</th>
                        <th>Value Indicator</th>
                        <th>Value Description</th>
                        <th>Novelty</th>
                        <th></th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr *ngFor="let item of items">
            
                        <td>{{ getAnyObjectValue(item,'categoryCode') }}</td>
                        <td [ngClass]="{ 'enabled': getAnyObjectValue(item,'enabled'), 'disabled': !getAnyObjectValue(item,'enabled') }">
                            <label class="switch">
                            <!--@ts-ignore -->
                            <input type="checkbox" [(ngModel)]="item.enabled">
                            <span class="slider"></span>
                            </label>
                        </td>
            
                        <td>{{ renderDates(getAnyObjectValue(item,'dates')) }}</td>
            
            
                        <td>{{ item.mintSymbols.join(', ') }}</td>
                        <td>
                            <ul>
                            <li *ngFor="let feature of item.coinFeatures">
                                {{ feature.name }}: {{ feature.value }}
                            </li>
                            </ul>
                        </td>
                        <td [ngClass]="getLevelClass(item.valueIndicator.level)">
                            <div class="level-container" >
                              <button (click)="decreaseILevel(item)" class="decILBtn round-btn">-</button>
                              <div class="name">{{ item.valueIndicator.level }}</div>
                              <button (click)="increaseILevel(item)" class="incILBtn round-btn">+</button>
                            </div>
                          </td>
                          
                        <td>
                            <div class="description">{{ item.valueIndicator.description }}</div>
            
                        </td>
                        <td>{{ item.novelty }}</td>
                        <td>
                          <button (click)="removeItem(item)">Remove</button> 
                          <button (click)="moveUp(item)">↑</button>
                          <button (click)="moveDown(item)">↓</button>
                        </td>
                    </tr>
                    </tbody>
                </table>
              </div>
              
        </td>
    </tr>
</table>


