    <h1 class="gap">Robo Coin Coin Match:</h1>

    <mat-form-field appearance="fill" class="gap">
      <mat-label>diameter [mm]</mat-label>
      <input matInput name = "diameter" class="form-control gap" type="number" required [(ngModel)]="diameter">
    </mat-form-field>


    <mat-checkbox  [(ngModel)]="isDebug">debug</mat-checkbox>
    <mat-checkbox  [(ngModel)]="noImages">noimages</mat-checkbox>
    <mat-checkbox  [(ngModel)]="isdirty">isditry</mat-checkbox>

    <table>
    <tr *ngIf="coinScanToMatch!='' and coinScanToMatch!=null">
      <td>
          <span style="color:red;" *ngIf="message1">{{message1}}</span>
          <input #file1 type="file" class="gap imggap" accept='image/png' (change)="preview(file1.files,'1')" />
      </td>
      <td>
          <span style="color:red;" *ngIf="message2">{{message2}}</span>
          <input #file2 type="file" class="gap imggap"  accept='image/png' (change)="preview(file2.files,'2')" />
      </td>
    <tr>
      <td>
        <img [src]="imgURL1" class="gap imggap"  height="250" *ngIf="imgURL1">
      </td>
      <td>
        <img [src]="imgURL2" class="gap imggap"  height="250" *ngIf="imgURL2">
      </td>
    </tr>
    </table>
    <button [disabled]="imgURL1==null||imgURL2==null" class="btn btn-primary gap" (click)="coinMatch2()">Match2</button>

    <mat-progress-bar mode="indeterminate" *ngIf="isWait" ></mat-progress-bar>
<div *ngIf="recognizedData!=null ">
  <h1 class="gap">Recognized Coin:</h1>
  <table class="table table-striped">
    <tr>
      <td>
          <label>imageObverse</label>
          <img *ngIf="recognizedData['coinrecognize']['imageObverse']===''" width="30px" [src]="'assets/img/nofoto_small.png'" />
          <img *ngIf="recognizedData['coinrecognize']['imageObverse']!==''" [src]="'data:image/jpg;base64,'+recognizedData['coinrecognize']['imageObverse'] | safeHtml"  width="150px"/>
      </td>

      <td>
        <label>imageObverse</label>
        <img *ngIf="recognizedData['coinrecognize']['imageReverse']===''" width="30px" [src]="'assets/img/nofoto_small.png'" />
        <img *ngIf="recognizedData['coinrecognize']['imageReverse']!==''" [src]="'data:image/jpg;base64,'+recognizedData['coinrecognize']['imageReverse'] | safeHtml"  width="150px"/>
    </td>
    </tr>
  </table>
  <pre>{{ recognizedData | json}}</pre>
</div>
<!-- 
<div *ngIf="bestMatch!=null ">
    <h1 class="gap">Matched Coin:</h1>
    <table class="table table-striped">
      <tr>
        <td>
            <label>imageObverse</label>
            <img *ngIf="bestMatch['coin']['imageObverseSmall']===''" width="30px" [src]="'assets/img/nofoto_small.png'" />
            <img *ngIf="bestMatch['coin']['imageObverseSmall']!==''" [src]="'data:image/jpg;base64,'+bestMatch['coin']['imageObverseSmall'] | safeHtml"  width="150px"/>
        </td>

        <td>
             <label>imageReverse</label>
            <img *ngIf="bestMatch['coin']['imageReverseSmall']===''" width="30px" [src]="'assets/img/nofoto_small.png'" />
            <img *ngIf="bestMatch['coin']['imageReverseSmall']!==''" [src]="'data:image/jpg;base64,'+bestMatch['coin']['imageReverseSmall'] | safeHtml"  width="150px"/>
        </td>

          <td>
            <tr><td><a [routerLink]="['/coinview', bestMatch['coin']['rcid']]">{{bestMatch['coin']['title']}}</a></td></tr>
            <tr><td>catCode: {{ bestMatch['coin']['categoryCode'] }}<br>
                    rcid: {{ bestMatch['coin']['rcid'] }}<br>
                    <div class="cvalue">coin value range: {{matchValue}}</div><br>
                    <div class="cvalue">matched coin value: {{bestMatch['coinValue']}}</div><br>
                    <div [ngClass]="{'cred' : bestMatch['score']==0,'cgreen':bestMatch['score']>0}" >match score:{{bestMatch['score']}}</div>
                    <div [ngClass]="{'cred' : bestMatch['novelty']==true,'cgreen':bestMatch['novelty']==false}" >novelty detection:{{bestMatch['novelty']}}</div>
                    <div [ngClass]="{'cred' : bestMatch['novelty']==true,'cgreen':bestMatch['novelty']==false}" >coin quality:{{bestMatch['coinQuality']}}</div>
                    <div *ngFor="let templateData of bestMatch['templateResults'] ">
                        {{templateData['paramType']}}:{{templateData['paramValue']}}
                    </div>
                  </td></tr>
        </td>
      </tr>
      <tr>
        <h1>Normalized Input (pivot correction)</h1>
      </tr>
      <tr>
        <td>cut-imageObverse
            <img *ngIf="bestMatch['imageObverse']!==''" [src]="'data:image/jpg;base64,'+bestMatch['imageObverse'] | safeHtml"  width="150px"/>
        </td>

        <td>cut-imageReverse
            <img *ngIf="bestMatch['imageReverse']!==''" [src]="'data:image/jpg;base64,'+bestMatch['imageReverse'] | safeHtml"  width="150px"/>
        </td>
        <td></td>
        </tr>

    </table>
</div>
<div *ngIf="templateDebugData!=null">
  <h1>Debug Info</h1>
  <table>
    <tr *ngFor="let tdebug of templateDebugData;index as i">
      <td>
        <img  [src]="'data:image/jpg;base64,'+tdebug | safeHtml"  width="500px"/>
      </td>
    </tr>
  </table>
</div>

<div *ngIf="matchingData!=null && templateDebugData==null">
  <h1>Debug Info</h1>
  <table>
    <tr>
      <td>Side 1</td>
    </tr>
    <tr *ngFor="let cand of matchingData[0]['matchingCandidates'] ;index as i">
      <td>
        <img  [src]="'data:image/jpg;base64,'+cand | safeHtml"  width="1000px"/>
      </td>
    </tr>
    <tr>
      <td>Side 2</td>
    </tr>
    <tr *ngFor="let cand of matchingData[1]['matchingCandidates'] ;index as i">
      <td>
        <img  [src]="'data:image/jpg;base64,'+cand | safeHtml"  width="1000px"/>
      </td>
    </tr>
  </table>
</div>

 -->
