import { Pipe, type PipeTransform } from '@angular/core';
import * as dayjs from 'dayjs';
import * as isBetween from 'dayjs/plugin/isBetween';
dayjs.extend(isBetween);

@Pipe({
    name: 'filter',
    standalone: false
})
export class FilterPipe implements PipeTransform {

  transform(coins: any, filterFormValue: any): any {
    if (!coins || !filterFormValue) {
      return coins;
    }

    if(filterFormValue.sortType === 'SCANDATE') {
      coins.sort((a, b) => new Date(a.timestamp).getTime() - new Date(b.timestamp).getTime());
    }

    else if (filterFormValue.sortType === 'VALUE') {
      coins.sort((a, b) => parseFloat(a.level) - parseFloat(b.level));
    }

    return coins.filter((coin) => {
      const categoryMatch = filterFormValue.category === '' || coin.category === filterFormValue.category;
      const coinDateMatch = filterFormValue.coinDate === '' || coin.date === filterFormValue.coinDate;
      const coinMintArrMatch =
        filterFormValue.coinMintArr?.length === 0 || filterFormValue.coinMintArr?.includes(coin.mintSymbol);
      const coinExtraFeature = filterFormValue.coinExtraFeature === '' || filterFormValue.coinExtraFeature?.id + ' - ' + filterFormValue.coinExtraFeature?.value === (Object.keys(coin.coinFeatures))[0] + ' - ' + (Object.values(coin.coinFeatures))[0];
      const coinDateRange = (!filterFormValue.dateRange?.from && !filterFormValue.dateRange?.to) || (dayjs(coin.timestamp).isBetween(filterFormValue.dateRange.from, filterFormValue.dateRange.to))

      return categoryMatch && coinDateMatch && coinMintArrMatch && coinExtraFeature && coinDateRange;
    });
  }
}
