<table>
    <tr>
        <td class='sortListItem'>
            <table>
                <tr>
                    <td class="btn-tasks-header">SORTER TASKS</td>
                </tr>
                <tr *ngFor="let task of sorterList">
                    <td><button class="btn btn-primary btn-tasks" (click)="reloadList(task['sortingTaskId'])">{{task['sortingTaskId']}}</button></td>
                    
                </tr>
            </table>
        </td>
        <td class="coinsorter-details">
            <mat-progress-bar mode="indeterminate" *ngIf="isWait"></mat-progress-bar>
            <table>
                <tr>
                    <table *ngIf="sorterTaskHeader!=null" class="mainheader table table-striped">
                        <tr>
                            <td>id:</td><td>{{sorterTaskHeader['stats']['totalCoins']}}&#64;{{sorterTaskHeader['sortingTaskId']}}</td>
                        </tr>
                        <tr>    
                            <td>totalNominalValue:</td><td>{{sorterTaskHeader['stats']['totalNominalValue']}}</td>
                        </tr>
                        <tr>  
                            <td>totalPredictedValue:</td><td>{{sorterTaskHeader['stats']['totalPredictedValue']}}</td>
                        </tr>
                        <tr>  
                            <td>NPRatio:</td><td>{{sorterTaskHeader['stats']['NPRatio']}}</td>
                        </tr>
                        <tr>  
                            <td>totalProcessingTime:</td><td>{{sorterTaskHeader['stats']['totalProcessingTime']}}</td>
                        </tr>
                        <tr>
                            <td><button class="btn btn-primary btn-tasks" (click)="reloadList(sorterTaskHeader['sortingTaskId'])">reload</button></td>
                            <td><button class="btn btn-primary btn-tasks" (click)="deleteSorterList(sorterTaskHeader['sortingTaskId'])">delete Sorter Task</button></td>
                        </tr>
                    </table>
                </tr>
                <tr>
                    <table *ngIf="sorterTaskData!=null" class="mainheader table table-striped" matSort (matSortChange)="sortData($event)">
                        <tr>
                            <th mat-sort-header="pos">#</th>
                            <th mat-sort-header="categoryCode">categoryCode</th>
                            <th mat-sort-header="date">date</th>
                            <th mat-sort-header="mintSymbol">mint</th>
                            <th mat-sort-header="coinQuality">quality</th>
                            <th mat-sort-header="nominalValue">nominalVal</th>
                            <th mat-sort-header="predictedValue">predictedVal</th>
                            <th mat-sort-header="indicator">indicator</th>
                            <th mat-sort-header="novelty">novelty</th>
                            <th mat-sort-header="multimatch">multimatch</th>
                            <th mat-sort-header="coinscan">coinscan</th>
                        </tr>
                        <tr *ngFor="let cs of sorterTaskData">
                            <td>{{cs['pos']+1}}</td>
                            <td class="C{{cs['categoryCode']}}">{{cs['categoryCode']}}</td>
                            <td>{{cs['date']}}</td>
                            <td>{{cs['mintSymbol']}}</td>
                            <td>{{cs['coinQuality']}}</td>
                            <td>{{cs['nominalValue']}}</td>
                            <td>{{cs['predictedValue']}}</td>
                            <!--
                            <td [ngClass]="{'normalizedValueOrange' : cs['normalizedValue'] > 50 && cs['normalizedValue'] < 75 , 'normalizedValueRed' :cs['normalizedValue'] >= 75 }">{{cs['normalizedValue']}}</td>
                            -->
                            <td [ngClass]="{'normalizedValueLOW' : getPriceRulesIndicatorLevel(cs) == 'LOW' ,'normalizedValueMED' : getPriceRulesIndicatorLevel(cs) == 'MED' , 'normalizedValueHIGH' :getPriceRulesIndicatorLevel(cs) == 'HIGH' }">{{getPriceRulesIndicatorLevel(cs)}}</td>
                           
                            
                            <td [ngClass]="{'noveltyTrue' : cs['novelty'] , 'noveltyFalse' :cs['novelty']}">{{cs['novelty']}}</td>
                            <td [ngClass]="{'noveltyTrue' : cs['multipleMatch'] , 'noveltyFalse' :cs['multipleMatch']}">{{cs['multipleMatch']}}</td>
                            <td><a [routerLink]="['/coinscanview', cs['scanId']]">{{cs['scanId']}}</a></td>
                        </tr>
                    </table>
                </tr>
            </table>
        </td>
    </tr>
</table>
