import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { CoinsearchService } from '../coinsearch.service';
import {Sort} from '@angular/material/sort';

@Component({
  selector: 'app-sorterlist',
  templateUrl: './sorterlist.component.html',
  styleUrls: ['./sorterlist.component.css']
})
export class SorterlistComponent implements OnInit {
  public sorterTaskId: string | null = null;
  public sorterList       = null;
  public sorterTaskHeader = null;
  //public sorterTaskData   = null;
  public sorterTaskData: any[] | null = null;
  public isWait           = false;
  constructor(private _coinSearchService: CoinsearchService, private _Activatedroute: ActivatedRoute) { }

  ngOnInit(): void {
    this.isWait=true;
    this._coinSearchService.getSorterGetList()
    .subscribe (
      data => this.renderSortList(data),
      error => console.error('Error!', error)
    );
    const srtTaskId=  this._Activatedroute.snapshot.paramMap.get('tid');
    if (srtTaskId != null && srtTaskId !== '' ) {
      this.sorterTaskId = srtTaskId;
      this.reloadList(this.sorterTaskId)
    }
   
  }

  renderSortList(data:any){
    console.log(data);
    this.sorterList=data;
  }

  reloadList(taskId:string){
    this.isWait=true;
    if (taskId !== '' && taskId != null){
      this._coinSearchService.getSorterTaskData(taskId)
      .subscribe (
        data => this.renderSortTask(data),
        error => console.error('Error!', error)
      );
    }
  }

  postDelete(){
    this.sorterTaskData=null;
    this.sorterTaskHeader=null;
    this._coinSearchService.getSorterGetList()
    .subscribe (
      data => this.renderSortList(data),
      error => console.error('Error!', error)
    );
    this.isWait=false;
  
  }

  deleteSorterList(taskId:string){
    this.isWait=true;
    if (taskId !== '' && taskId != null){
      this._coinSearchService.deleteSorterTaskData(taskId)
      .subscribe (
        data => this.postDelete(),
        error => console.error('Error!', error)
      );
    }
  }
  
  renderSortTask(data:any){
    if (data===null){
      return;
    }
    const sorterData=data['coins'];
    if (sorterData===null){
      return;
    }
    this.sorterTaskData=sorterData;
    
    var datalen=0;
    //check if this.sorterTaskData is array


    if (this.sorterTaskData && (this.sorterTaskData as any[]).length > 0){
      datalen=(this.sorterTaskData as any[]).length;
    }

    // if (this.sorterTaskData) {
    //   for(let i=0; i<datalen; i++){
    //     this.sorterTaskData[i]['pos']=i
    //   }
    // }
    if (this.sorterTaskData) {
      for(let i = 0; i < datalen; i++) {
        (this.sorterTaskData[i] as { pos: number, [key: string]: any })['pos'] = i;
      }
    }
    data['coins']=[];
    this.sorterTaskHeader=data;
    console.log(this.sorterTaskHeader);
    this.isWait=false;
  }

  
  sortData(sort: Sort) {
    if (this.sorterTaskData===null){
      return;
    }
    const data = (this.sorterTaskData as any[]).slice();
    if (data===null){
      return;
    }
    if (!sort.active || sort.direction === '') {
      if (data!=null){
        this.sorterTaskData = data;
      }
      return;
    }

    this.sorterTaskData = data.sort((a:any, b:any) => {
      const isAsc = sort.direction === 'asc';
      switch (sort.active) {
        case 'pos': return compare(a['timestamp'], b['timestamp'], isAsc);
        case 'categoryCode': return compare(a['categoryCode'], b['categoryCode'], isAsc);
        case 'date': return compare(a['date'], b['date'], isAsc);
        case 'mintSymbol': return compare(a['mintSymbol'], b['mintSymbol'], isAsc);
        case 'coinQuality': return compare(a['coinQuality'], b['coinQuality'], isAsc);
        case 'nominalValue': return compare(a['nominalValue'], b['nominalValue'], isAsc);
        case 'predictedValue': return compare(a['predictedValue'], b['predictedValue'], isAsc);
        //case 'normalizedValue': return compare(a['normalizedValue'], b['normalizedValue'], isAsc);
        case 'normalizedValue': return compare(this.getPriceRulesIndicatorLevel(a), this.getPriceRulesIndicatorLevel(b), isAsc);
        default: return 0;
      }
    });
  }

  getPriceRulesIndicatorLevel(coin:any){
    console.log(coin);
    if(coin.hasOwnProperty('rulesValueIndicator') && coin['rulesValueIndicator'].hasOwnProperty('level')) {
      return coin['rulesValueIndicator']['level'];
    }
    return '---';
  }

}


function compare(a: number | string, b: number | string, isAsc: boolean) {
  return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
}